<div class="content">
  <div class="row mt-3">
    <p-toast></p-toast>
    <div class="col-md-6 " *ngIf="auditor.status=='SUBMITTED' || auditor.frcId!=null">
      <p-card Enlisted="Auditor Approval Status" [style]="{ width: '460px' }">
        <!-- <ng-template  [style]="{ height: '460px' }" pTemplate="header">
        </ng-template> -->
        <h1 class="text-center">Auditor Information</h1>
        <hr>
        <h2 class="text-center" style="padding: 0; margin: 0;">{{auditor.fullName}}</h2>
        <h3 class="text-center" style="padding: 0; margin: 0;"><strong>Auditor status : </strong><span
            [ngClass]="areAllItemsApprovedForAuditor() ? 'text-primary' : 'text-danger'"><u>{{areAllItemsApprovedForAuditor()
              ? 'Enlisted': 'Not Enlisted'}}</u></span></h3>
              <hr>
        <div class="step" [class.active]="item.taskStatus=='RUNNING' || item.taskStatus=='APPROVED'"
          *ngFor="let item of auditorStatus">
          <div *ngIf="item.taskStatus=='APPROVED'">
            <div class="circle"><i class="fa fa-check"></i></div>
          </div>
          <div *ngIf="item.taskStatus=='REJECT'">
            <div class="circle"><i class="fa fa-close" aria-hidden="true"
                style="text-align: center; margin-top: 4%; font-size:32px;color:rgb(255, 0, 0)"></i></div>
          </div>
          <div *ngIf="item.taskStatus=='RUNNING'">
            <div class="circle">{{item.stepSeq}}</div>
          </div>
          <div *ngIf="item.taskStatus==null">
            <div class="circle">{{item.stepSeq}}</div>
          </div>

          <div>
            <div class="title">{{item.stepName}}</div>
            <div class="caption">{{item.taskStatus}}</div>
          </div>

        </div>
        <!-- <div class="step step-active">
            <div>
              <div class="circle">2</div>
            </div>
            <div>
              <div class="title">Overall review</div>
            </div>
          </div>
          <div class="step">
            <div>
              <div class="circle">3</div>
            </div>
            <div>
              <div class="title">Final approve</div>
            </div>
          </div> -->

        <div class="step" *ngIf="areAllItemsApprovedForAuditor()">
          <div>
            <div class="circle">4</div>
          </div>
          <div>
            <div class="title" style="cursor:pointer;">
              <p-confirmDialog></p-confirmDialog>
                <p-button label="Download certificate" [loading]="isDownloadingCertificateAuditor" (onClick)="getCertificateForAuditor()"></p-button>
            </div>
          </div>
        </div>
        <!-- <ng-template pTemplate="footer">
           
        </ng-template> -->
      </p-card>
    </div>
    <div class="col-md-6" *ngIf="firmInfo?.status=='SUBMITTED' || firmInfo?.firmRegId!=null">
      <p-card Enlisted="Firm Approval Status" [style]="{ width: '460px' }">
        <!-- <ng-template pTemplate="header">
        </ng-template> -->

        <h2 class="text-center">Firm Information</h2>
        <hr>
        <h3 class="text-center" style="padding: 0; margin: 0;">{{firmInfo.firmName}}</h3>
        <h3 class="text-center" style="padding: 0; margin: 0;"><strong>Firm status : </strong><span
            [ngClass]="areAllItemsApprovedForFirm() ? 'text-primary' : 'text-danger'"><u>{{areAllItemsApprovedForFirm()
              ? 'Enlisted': 'Not Enlisted'}}</u></span></h3>
              <hr>

        <div class="step" [class.active]="item.taskStatus=='RUNNING' || item.taskStatus=='APPROVED'"
          *ngFor="let item of firmStatus">
          <div *ngIf="item.taskStatus=='APPROVED'">
            <div class="circle"><i class="fa fa-check"></i></div>
          </div>
          <div *ngIf="item.taskStatus=='REJECT'">
            <div class="circle"><i class="fa fa-close" aria-hidden="true"
                style="text-align: center; margin-top: 4%; font-size:32px;color:rgb(255, 0, 0)"></i></div>
          </div>
          <div *ngIf="item.taskStatus=='RUNNING'">
            <div class="circle">{{item.stepSeq}}</div>
          </div>
          <div *ngIf="item.taskStatus==null">
            <div class="circle">{{item.stepSeq}}</div>
          </div>

          <div>
            <div class="title">{{item.stepName}}</div>
            <div class="caption">{{item.taskStatus}}</div>
          </div>

        </div>
        <!-- <div class="step step-active">
            <div>
              <div class="circle">2</div>
            </div>
            <div>
              <div class="title">Overall review</div>
            </div>
          </div>
          <div class="step">
            <div>
              <div class="circle">3</div>
            </div>
            <div>
              <div class="title">Final approve</div>
            </div>
          </div> -->

        <div class="step" *ngIf="areAllItemsApprovedForFirm() && isUserHasFirmCertificate">
          <div>
            <div class="circle">4</div>
          </div>
          <div>
            <div class="title">
              <p-button label="Download certificate" [loading]="isDownloadingCertificateFirm" (onClick)="getCertificateForFirm()"></p-button>
              <!-- <a style="cursor:pointer;" (click)="getCertificateForFirm()" target="_blank"
                rel="noopener noreferrer"> Download FRC's Enlistment FIRM certificate</a> -->
              </div>
          </div>
        </div>

        <!-- <ng-template pTemplate="footer">
           
        </ng-template> -->
      </p-card>

    </div>
  </div>
</div>