import { Cookie } from 'ng2-cookies';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { StorageService } from '@services/storage.service';
import { API } from '@/const';

const TOKEN_HEADER_KEY = 'Authorization';
const CONTENT_TYPE_HEADER_KEY = 'Content-Type';
const CONTENT_TYPE_HEADER_VALUE = 'application/json';
const ALLOW_ORIGIN_KEY = 'Access-Control-Allow-Origin';
const ALLOW_ORIGIN_VALUE = '*';

@Injectable()
export class HttpHeadersInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let authReq = request;
    authReq = authReq.clone({ headers: authReq.headers.set('latLng', API.latLng) });
    const token = Cookie.get('access_token') || null;

    if (token != null) {
      let _formData = authReq.serializeBody();
      if(_formData instanceof FormData){
        //authReq = authReq.clone({ headers: authReq.headers.set(CONTENT_TYPE_HEADER_KEY, 'multipart/form-data') });
      }else{
        authReq = authReq.clone({ headers: authReq.headers.set(CONTENT_TYPE_HEADER_KEY, CONTENT_TYPE_HEADER_VALUE) });
      }
      authReq = authReq.clone({ headers: authReq.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token) });
    }
    return next.handle(authReq);
  }
}

export const authInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: HttpHeadersInterceptor, multi: true }
];

