<div class="form-group">
    <label *ngIf="lblon" for="exampleInputFile">{{ label }}</label>
    <div class="input-group">
        <div class="input-group-append">
            <span class="input-group-text" *ngIf="neededPreview">
              <a [href]="url" target="_blank"><i _ngcontent-uuu-c450="" class="btn btn-outline-none btn-xs fa fa-eye"></i></a>
            </span>
        </div>
        <div class="custom-file">
            <input [disabled] = "disabled"
                type="file"
                class="custom-file-input"
                id="exampleInputFile"
                [accept]="accept"
                (change)="upload($event)"
            />
            <label class="custom-file-label" for="exampleInputFile"
                >{{defaultMessage}}</label
            >
        </div>

        <div class="input-group-append" *ngIf="loading">
          <span class="input-group-text">
            <span
              class="spinner-border spinner-border-sm ml-2"
              role="status"
              aria-hidden="false"
          ></span>
      </span>
      </div>
        <div id="fileDeleteIcon" tabindex="-1" class="fileDeleteIcon input-group-append" *ngIf="neededDelete" (click)="delete()">
            <span class="input-group-text">
                <i
                    _ngcontent-uuu-c450=""
                    class="btn btn-outline-danger btn-xs fa fa-trash"
                ></i>
            </span>
        </div>
    </div>
</div>
