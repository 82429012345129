import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from '@/const'

@Injectable()
export class SignupUserService {

    constructor(private http: HttpClient) { }

    sendOTPToEmail(data) {
        return this.http.post<any>(API.BASE_API_URL + API.SEND_EMAIL_OTP_USER, data)
            .toPromise()
            .then(res => <any>res)
            .then(data => { return data; });
    }

    verifyOTPToEmail(data) {
        return this.http.post<any>(API.BASE_API_URL + API.VERIFY_EMAIL_OTP_USER, data)
            .toPromise()
            .then(res => <any>res)
            .then(data => { return data; });
    }

    sendOTPToMobile(data) {
        return this.http.post<any>(API.BASE_API_URL + API.SEND_MOBILE_OTP_USER, data)
            .toPromise()
            .then(res => <any>res)
            .then(data => { return data; });
    }

    signup(data) {
        return this.http.post<any>(API.BASE_API_URL + API.SIGNUP_USER, data)
            .toPromise()
            .then(res => <any>res)
            .then(data => { return data; });
    }

    verifyOTPToMobile(data) {
        return this.http.post<any>(API.BASE_API_URL + API.VERIFY_MOBILE_OTP_USER, data)
            .toPromise()
            .then(res => <any>res)
            .then(data => { return data; });
    }
}