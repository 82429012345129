import { Component, OnInit } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { EnableMenuService } from './enablemenu.service';


@Component({
  selector: 'app-enablemenu',
  templateUrl: './enablemenu.component.html',
  styleUrls: ['./enablemenu.component.scss'],
  providers: [DialogService, EnableMenuService]
})
export class EnablemenuComponent implements OnInit {
  display: boolean = false;
  displayModal: boolean;
  checkedAuditor: boolean;
  checkedFirm: boolean;
  setting: any;
  constructor(public dialogService: DialogService,
    private es: EnableMenuService) { }

  ngOnInit(): void {
    this.showModalDialog();
    this.getSetting();
    console.log(this.setting);
    
  }

  showModalDialog() {
    this.displayModal = true;
  }

  getSetting(){
    this.es.getUserSetting()
    .then(res=>{
      this.setting = res;
      this.checkedAuditor = Boolean(res.setting.enableAudit);
      this.checkedFirm = Boolean(res.setting.enableFirm);
    }).catch(e=>{
      console.log(e);
      
    })
    return true;
  }

  UpdateSetting(){
    
    return true;
  }

}
