import { AuthService } from '@services/auth.service';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

import {
    CanActivate,
    CanActivateChild,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree,
    Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { AppService } from '@services/app.service';
import { StorageService } from '@services/storage.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
    constructor(private router: Router, 
        private storageService: StorageService, 
        private _auth: AuthService, private tokenVerify: JwtHelperService) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return true;
    }

    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        //let isLoggedIn = this._auth.checkCredentials();


        if (!this._auth.userLoggedIn() && window.location.pathname === '/') {
            // Redirect to the '/home' route
            this.router.navigate(['/home']);
            return false; // Prevent the current route from loading
          }

        if(!this._auth.userLoggedIn()){
            this.router.navigate(['/login']);
            return false;
        }

        if(this._auth.isTokenExpired()){
            this.storageService.logout();
            this.router.navigate(['/login']);
            return false;
        }
        
        
        if(this._auth.hasAccessRole(next)){
            return true;
        }else{
            alert("Access denied")
            return false;
        }

        //   
        // if (!isTokenExpired) {
        //     return true;
        // }
        
    }
}


