import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Config } from '@/const';
import { SignupUserService } from '@services/signup.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mobile',
  templateUrl: './mobile.component.html',
  styleUrls: ['./mobile.component.scss'],
  providers: [SignupUserService]
})
export class MobileComponent implements OnInit {

  signupForm: UntypedFormGroup;
  otpForm: UntypedFormGroup;
  submitted = false;
  isLoading = false;
  message = ""
  isEnableMobileForm = true;

  constructor(
    public dialog: MatDialog,
    private primengConfig: PrimeNGConfig,
    private fb: UntypedFormBuilder,
    private ss: SignupUserService,
    private router: Router
  ) { }

  public Logo = '';
  ngOnInit(): void {
    this.Logo = Config.frcLogo;
    this.signupForm = this.fb.group({
      mobile: ['', Validators.compose([Validators.required,  Validators.pattern(/(^(\+88|0088)?(01){1}[3456789]{1}(\d){8})$/)])]
    });

    this.otpForm = this.fb.group({
      mobile: [''],
      otp: ['', Validators.compose([Validators.required])],
    });
  }


  


  get f() {
    return this.signupForm.controls;
  }

  get o() {
    return this.otpForm.controls;
  }


  SendMobileVerificationCode() {
    if(this.signupForm.valid){
      this.isLoading = true;
      this.ss.sendOTPToMobile(this.signupForm.value)
      .then(res =>{
        this.isEnableMobileForm = false;
        let msg = res.ErrorCode==0 ? "A verification code will be sent to your mobile phone via SMS": "Sorry! Mobile verification failed!"
        alert(msg)
        this.isLoading = false;
      })
      .catch(e =>{
        this.isLoading = false;
        alert("Failed to send OTP")
        
      })
    }  

  }

  verifyMobileVerificationCode() {    
    if(this.otpForm.valid){
      this.isLoading = true;
      this.isEnableMobileForm = false;
      let mobile = this.signupForm.get('mobile').value;
      this.otpForm.get('mobile').setValue(mobile);
      this.ss.verifyOTPToMobile(this.otpForm.value)
      .then(res =>{
        
        alert(res.message)
        this.isLoading = false;
        if(res.status != 'Error'){
          sessionStorage.setItem('signup_mobile', mobile);
          this.router.navigate(['/password-setup']);
        }
        
      })
      .catch(e =>{
        this.isLoading = false;
        alert("Invalid OTP")
        
      })
    }  

  }
}
