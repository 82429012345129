
<strong class="float-left">
    <span>Copyright &copy; {{ currentYear }}</span>
    <a
        href="http://frc.gov.bd/"
        target="_blank"
        rel="noopener noreferrer"
        style="margin: 0"
    >
        FRCBD</a
    >
    <span>.</span>
</strong>
<span> All rights reserved.</span>
