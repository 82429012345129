<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1 class="m-0 text-dark">Dashboard</h1>
            </div>

            <!-- login.component.html -->
   <!-- <div class="col-sm-6" *ngIf="showMessage">
    Your message display code here
  </div> -->

        </div>
    </div>
</div>

<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12 mb-2" style="">
                <a href="http://frc.gov.bd/site/page/2638e7c4-bd57-4249-9257-4c664c5a5ab7/-" target="_blank" rel="noopener noreferrer">
                    নিরীক্ষক ও নিরীক্ষা ফার্ম তালিকাভুক্তির আবেদন করার সাধারণ গাইডলাইন
                </a>
            </div>
            <div class="col-md-8">
                <div class="jumbotron" *ngIf="!showMessage">
                    <div class="container">
                      <h1 class="display-6" style="color: red;">Firm creation warning!!</h1>
                      <p class="lead text-danger" style="font-weight: 400;">Only the firm in-charge is required to create or fill up firm information. If you are not the firm in-charge, there is no need for you to create or fill up firm information.</p>
                    </div>
                  </div>
            </div>
            <p-dialog header="Firm creation warning" [(visible)]="showMessage" [modal]="true" [closable]="false" [style]="{width: '40vw'}">
                <!-- Your message content here -->
                <p class="p-dialog-text">Only the firm in-charge is required to create or fill up firm information. If you are not the firm in-charge, there is no need for you to create or fill up firm information.</p>
                <p-footer>
                  <button pButton type="button" label="Close" (click)="closePopup()" class="p-button-link"></button>
                </p-footer>
              </p-dialog>
        </div>
    </div>
</section>
<section class="content mt-2 mb-2">
    <div class="container">
        <div class="row">
            <div class="col-md-12" *ngIf="!isAdmin">
                <app-workflow-status></app-workflow-status>
            </div>

        </div>
    </div>

</section>
