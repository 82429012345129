
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from '@/const'

@Injectable()
export class EnableMenuService {

    constructor(private http: HttpClient) { }

    getUserSetting() {
        return this.http.get<any>(API.BASE_API_URL + API.GET_SETTINGS_USER)
            .toPromise()
            .then(res => <any>res)
            .then(data => { return data; });
    }

    updateSetting(data) {
        return this.http.post<any>(API.BASE_API_URL + API.UPDATE_SETTINGS_USER, data)
            .toPromise()
            .then(res => <any>res)
            .then(data => { return data; });
    }
}