import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from '@/app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from '@modules/main/main.component';
import { LoginComponent } from '@modules/login/login.component';
import { HeaderComponent } from '@modules/main/header/header.component';
import { FooterComponent } from '@modules/main/footer/footer.component';
import { MenuSidebarComponent } from '@modules/main/menu-sidebar/menu-sidebar.component';
import { BlankComponent } from '@pages/blank/blank.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProfileComponent } from '@pages/profile/profile.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DashboardComponent } from '@pages/dashboard/dashboard.component';
import { ToastrModule } from 'ngx-toastr';
import { MessagesComponent } from '@modules/main/header/messages/messages.component';
import { NotificationsComponent } from '@modules/main/header/notifications/notifications.component';
import { ButtonComponent } from './components/button/button.component';
import { registerLocaleData, CommonModule } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import { UserComponent } from '@modules/main/header/user/user.component';
import { ForgotPasswordComponent } from '@modules/forgot-password/forgot-password.component';
import { RecoverPasswordComponent } from '@modules/recover-password/recover-password.component';
import { LanguageComponent } from '@modules/main/header/language/language.component';
import { PrivacyPolicyComponent } from './modules/privacy-policy/privacy-policy.component';
import { MainMenuComponent } from './pages/main-menu/main-menu.component';
import { SubMenuComponent } from './pages/main-menu/sub-menu/sub-menu.component';
import { MenuItemComponent } from './components/menu-item/menu-item.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { DropdownMenuComponent } from './components/dropdown/dropdown-menu/dropdown-menu.component';
import { ControlSidebarComponent } from './modules/main/control-sidebar/control-sidebar.component';
import { StoreModule } from '@ngrx/store';
import { authReducer } from './store/auth/reducer';
import { uiReducer } from './store/ui/reducer';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SelectComponent } from './components/select/select.component';
import { RegistrationComponent } from './modules/authentication/registration/registration.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { MaterialModule } from './material-module'
import { AuthService } from '@services/auth.service';
import { authInterceptorProviders } from './utils/http-headers.interceptor';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';

import {DialogModule, Dialog} from 'primeng/dialog';
import { JwtHelperService, JWT_OPTIONS} from '@auth0/angular-jwt';
import { EmailVerifyComponent } from './modules/authentication/email-verify/email-verify.component';
import { MessageModule } from 'primeng/message';
import { TabMenuModule } from 'primeng/tabmenu';
import { MenutabComponent } from '@modules/auditor/reg/menutab/menutab.component';
import { MobileComponent } from './modules/authentication/registration/mobile/mobile.component';
import { PasswordComponent } from './modules/authentication/registration/password/password.component';
import {CheckboxModule} from 'primeng/checkbox';
import {EnablemenuComponent } from './modules/main/enablemenu/enablemenu.component';
import {DynamicDialogModule} from 'primeng/dynamicdialog';
import {ButtonModule} from 'primeng/button';
import {ToggleButtonModule} from 'primeng/togglebutton';
import { ConfirmPopupModule} from 'primeng/confirmpopup';
import { AdminDashboardComponent } from './pages/dashboard/admin-dashboard/admin-dashboard.component';
import { UserDashboardComponent } from './pages/dashboard/user-dashboard/user-dashboard.component';
import { WorkflowStatusComponent } from './components/workflow-status/workflow-status.component';
import { StepsModule } from 'primeng/steps';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { CardModule } from 'primeng/card';
import { PublicHomeComponent } from './pages/public-home/public-home.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MycommonModule } from "./modules/mycommon/mycommon.module";

registerLocaleData(localeEn, 'en-EN');

@NgModule({
    declarations: [
        AppComponent,
        MainComponent,
        LoginComponent,
        HeaderComponent,
        FooterComponent,
        MenuSidebarComponent,
        BlankComponent,
        ProfileComponent,
        DashboardComponent,
        MessagesComponent,
        NotificationsComponent,
        ButtonComponent,
        UserComponent,
        ForgotPasswordComponent,
        RecoverPasswordComponent,
        LanguageComponent,
        PrivacyPolicyComponent,
        MainMenuComponent,
        SubMenuComponent,
        MenuItemComponent,
        DropdownComponent,
        DropdownMenuComponent,
        ControlSidebarComponent,
        SelectComponent,
        CheckboxComponent,
        EmailVerifyComponent,
        RegistrationComponent,
        MobileComponent,
        PasswordComponent,
        EnablemenuComponent,
        AdminDashboardComponent,
        UserDashboardComponent,
        WorkflowStatusComponent,
        PublicHomeComponent
    ],
    exports: [SelectComponent, ButtonComponent],
    providers: [AuthService, authInterceptorProviders, { provide: JWT_OPTIONS, useValue: JWT_OPTIONS }, JwtHelperService],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        StoreModule.forRoot({ auth: authReducer, ui: uiReducer }),
        HttpClientModule,
        AppRoutingModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        DropdownModule,
        DialogModule,
        StepsModule,
        ToastModule,
        ToastrModule.forRoot({
            timeOut: 3000,
            positionClass: 'toast-top-right',
            preventDuplicates: true
        }),
        NgbModule,
        MaterialModule,
        TableModule,
        FormsModule,
        MessageModule,
        TabMenuModule,
        CheckboxModule,
        DynamicDialogModule,
        ButtonModule,
        ToggleButtonModule,
        ConfirmPopupModule,
        CardModule,
        ConfirmDialogModule
        // ToastModule
        ,
        MycommonModule
    ]
})
export class AppModule { }
