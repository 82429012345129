<!-- <div *ngIf="isLoading" class="loader-overlay modal-content" >
    <main>
        <svg class="ip" viewBox="0 0 256 128" width="256px" height="128px" xmlns="http://www.w3.org/2000/svg">
            <defs>
                <linearGradient id="grad1" x1="0" y1="0" x2="1" y2="0">
                    <stop offset="0%" stop-color="#5ebd3e" />
                    <stop offset="33%" stop-color="#ffb900" />
                    <stop offset="67%" stop-color="#f78200" />
                    <stop offset="100%" stop-color="#e23838" />
                </linearGradient>
                <linearGradient id="grad2" x1="1" y1="0" x2="0" y2="0">
                    <stop offset="0%" stop-color="#e23838" />
                    <stop offset="33%" stop-color="#973999" />
                    <stop offset="67%" stop-color="#009cdf" />
                    <stop offset="100%" stop-color="#5ebd3e" />
                </linearGradient>
            </defs>
            <g fill="none" stroke-linecap="round" stroke-width="16">
                <g class="ip__track" stroke="#ddd">
                    <path d="M8,64s0-56,60-56,60,112,120,112,60-56,60-56"/>
                    <path d="M248,64s0-56-60-56-60,112-120,112S8,64,8,64"/>
                </g>
                <g stroke-dasharray="180 656">
                    <path class="ip__worm1" stroke="url(#grad1)" stroke-dashoffset="0" d="M8,64s0-56,60-56,60,112,120,112,60-56,60-56"/>
                    <path class="ip__worm2" stroke="url(#grad2)" stroke-dashoffset="358" d="M248,64s0-56-60-56-60,112-120,112S8,64,8,64"/>
                </g>
            </g>
        </svg>
    </main>
  </div> -->

  <!-- <div>
    <div class="nuxt-loading-indicator" style="position:fixed;top:0;right:0;left:0;pointer-events:none;width:auto;height:3px;opacity:0;background:repeating-linear-gradient(to right,#00dc82 0%,#34cdfe 50%,#0047e1 100%);background-size:Infinity% auto;transform:scaleX(0%);transform-origin:left;transition:transform 0.1s, height 0.4s, opacity 0.4s;z-index:999999;"></div>
    
    
    <div class="pulseapp">
     
        <div class="shell-container" ng-include="&#39;https://pulse-res-prd.idtech.com/zigzag_v2/latest/shell.html?sha=cc44aa9&amp;t=1713921362853&#39;"></div>
    </div>
    
</div> -->
  
  <ng-container *ngIf="isLoading">
    <div class="col-md-4"></div>
    <div class="col-md-4">
    
    <div class="modal-overlay">
      <div class="modal-content">
        <div *ngIf="isLoading" class="loader-overlay modal-content" >
            <main>
                <svg class="ip" viewBox="0 0 256 128" width="256px" height="128px" xmlns="http://www.w3.org/2000/svg">
                    <defs>
                        <linearGradient id="grad1" x1="0" y1="0" x2="1" y2="0">
                            <stop offset="0%" stop-color="#5ebd3e" />
                            <stop offset="33%" stop-color="#ffb900" />
                            <stop offset="67%" stop-color="#f78200" />
                            <stop offset="100%" stop-color="#e23838" />
                        </linearGradient>
                        <linearGradient id="grad2" x1="1" y1="0" x2="0" y2="0">
                            <stop offset="0%" stop-color="#e23838" />
                            <stop offset="33%" stop-color="#973999" />
                            <stop offset="67%" stop-color="#009cdf" />
                            <stop offset="100%" stop-color="#5ebd3e" />
                        </linearGradient>
                    </defs>
                    <g fill="none" stroke-linecap="round" stroke-width="16">
                        <g class="ip__track" stroke="#ddd">
                            <path d="M8,64s0-56,60-56,60,112,120,112,60-56,60-56"/>
                            <path d="M248,64s0-56-60-56-60,112-120,112S8,64,8,64"/>
                        </g>
                        <g stroke-dasharray="180 656">
                            <path class="ip__worm1" stroke="url(#grad1)" stroke-dashoffset="0" d="M8,64s0-56,60-56,60,112,120,112,60-56,60-56"/>
                            <path class="ip__worm2" stroke="url(#grad2)" stroke-dashoffset="358" d="M248,64s0-56-60-56-60,112-120,112S8,64,8,64"/>
                        </g>
                    </g>
                </svg>
            </main>
          </div>
      </div>
    </div>
</div>
    <div class="col-md-4"></div>
  </ng-container>