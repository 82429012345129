import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Config } from '@/const';
import { SignupUserService } from '@services/signup.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mobile',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss'],
  providers: [SignupUserService]
})
export class PasswordComponent implements OnInit {

  pform: UntypedFormGroup;
  submitted = false;
  isLoading = false;
  message = ""

  constructor(
    public dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private ss: SignupUserService,
    private router: Router
  ) { }

  public Logo = '';
  ngOnInit(): void {
    this.Logo = Config.frcLogo;
    this.pform = this.fb.group({
      fullName: ['', Validators.compose([Validators.required])],
      nid: ['', Validators.compose([Validators.required])],
      email: [''],
      mobile: [''],
      password: ['', Validators.compose([Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{6,}')])]
    });
  }


  


  get f() {
    return this.pform.controls;
  }


  signup() {
    if(this.pform.valid){
      this.isLoading = true;
      let body = this.pform.value;
      body['email'] = sessionStorage.getItem('signup_email');
      body['mobile'] = sessionStorage.getItem('signup_mobile');
      this.ss.signup(body)
      .then(res =>{
        console.log(res);        
        alert("Thanks! your account has been created successfully")
        this.isLoading = false;
        this.router.navigate(['/login'])
      })
      .catch(e =>{
        console.log(e);        
        alert("Invalid NID/Password policy")
        this.isLoading = false;
      })
    }  

  }
}
