<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-9 bg">
                <!-- <div class="text-left">
                    <a class="h1">
                        <img src="{{Logo}}" alt="logo">
                        <b></b></a>
                </div> -->
                <div class="header-site-info" id="header-site-info">

                    <div>

                        <div id="logo">
                                                <a title="

                                " href="/">
                                    <!-- <img alt="logo" height="70" src="http://frc.gov.bd/themes/responsive_npf/img/logo/logo.png"> -->
                                    <img alt="logo" height="70" src="{{Logo}}">
                                </a>

                        </div>

                        <!-- <div class="clearfix" id="site-name-wrapper">
                            <span id="site-name">
                                <a title="Home" href="/">
                                                        </a>
                            </span>
                                            <span id="site-name">
                                <a title="
                                    ফাইনান্সিয়াল রিপোর্টিং কাউন্সিল					" href="/">
                                    ফাইনান্সিয়াল রিপোর্টিং কাউন্সিল					</a>
                            </span>
                                <span id="slogan">
                                অর্থ বিভাগ, অর্থ মন্ত্রণালয়				</span>
                        </div> -->

                    </div>

                </div>
                <div class="row" style="margin-top: 80px;">

                    <div style="height: 90vh; width: 100%;">
                        <div class="body-text-login">
                            <h3>
                                Auditor and Audit Firm
                            </h3>
                            <h3>Enlistment Portal.
                            </h3>
                        </div>
                    </div>


                </div>

            </div>
            <div class="col-md-3 border shadow-lg" style="background-color: #f3f3f3;">
                <div class="container ">
                    <div class="col-md-12" *ngIf="isEnableEmailForm">
                        <h1 class="text-center mt-5">Sign Up</h1>
                        <form [formGroup]="signupForm" (ngSubmit)="SendEmailVerificationCode()">
                            <div class="form-group p-2">
                                <label for="email" class="label-font font-weight-normal ">Email</label>
                                <input autocomplete="on" type="email" class="form-control" formControlName="email" id="email"
                                    aria-describedby="emailHelp" placeholder="Enter email">
                                <small *ngIf="(f.email.touched || submitted) && f.email.errors?.required" id="emailHelp"
                                    class="p-error">Email is required.</small>
                                <small *ngIf="(f.email.touched || submitted) && f.email.errors?.email" id="emailHelp"
                                    class="p-error">Invalid email.</small>
                            </div>

                            <div class="form-group p-2">
                                <!-- <button class="btn btn-primary lg-button" type="submit">Send OTP</button> -->
                                <app-button
                                type="submit"
                                [block]="true"
                                [loading]="isLoading"
                                [icon]="'lock'"
                                [color]="'primary'">
                                Send OTP
                                </app-button>
                            </div>
                            <div class="form-group p-1 text-center">
                                Already registered? <a href="#">Sign in </a>
                            </div>

                        </form>
                    </div>
                    <div class="col-md-12" *ngIf="!isEnableEmailForm">
                        <h1 class="text-center mt-5">Sign Up</h1>
                        <form [formGroup]="otpForm" (ngSubmit)="verifyEmailVerificationCode()">
                            <div class="form-group p-2">
                                <label for="email" class="label-font font-weight-normal ">Enter OTP that was sent to
                                    email</label>
                                <input type="text" class="form-control" formControlName="otp" id="otp"
                                    aria-describedby="otpHelp" placeholder="Enter 6 digit verification code">
                                <small *ngIf="(o.otp.touched || submitted) && o.otp.errors?.required" id="otpHelp"
                                    class="p-error">OTP is required.</small>
                                <small *ngIf="(o.otp.touched || submitted) && o.otp.errors?.min" id="emailHelp"
                                    class="p-error">Invalid OTP code</small>
                                <small *ngIf="(o.otp.touched || submitted) && o.otp.errors?.max" id="emailHelp"
                                    class="p-error">Invalid OTP code</small>
                            </div>

                            <div class="form-group p-2">
                                <app-button
                                    type="submit"
                                    [block]="true"
                                    [loading]="isLoading"
                                    [icon]="'lock'"
                                    [color]="'primary'">
                                    Verify OTP
                                </app-button>
                            </div>
                            <div class="form-group p-1 text-center">
                                Already registered? <a [routerLink]="['/login']">Sign in </a>
                            </div>

                        </form>
                    </div>
                </div>

            </div>
        </div>
    </div>

</section>
