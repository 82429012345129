import { Component, OnInit } from '@angular/core';
import { AuthService } from '@services/auth.service';

@Component({
  selector: 'app-user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrls: ['./user-dashboard.component.scss']
})
export class UserDashboardComponent implements OnInit {

  constructor(private auth: AuthService) { }
  isAdmin: boolean = false;
  showMessage = false;
  ngOnInit(): void {
    this.isAdmin = this.auth.isAdmin();
    const messageShown = localStorage.getItem('messageShown');
    
    if (!messageShown) {
      // Set flag to indicate message has been shown
      localStorage.setItem('messageShown', 'true');
      this.showMessage = true; // Show message
    }
  }

  closePopup() {
    this.showMessage = false; // Close popup
  }

}
