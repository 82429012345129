
<p-button (click)="showBasicDialog()" icon="pi pi-paperclip" [label]="label"></p-button>

<p-dialog header="Long Content" [(visible)]="displayBasic" [style]="{width: '50vw'}">
  <div class="card">
    <p-tabView>
      <p-tabPanel header="Recent">

    <div *ngIf="images" class="row">
      <div *ngFor="let image of images; let index = index;" class="col-md-3 my-2" key="index">
          <img [src]="fileserver+image" class="img-fluid" alt="Image Not Found" style="cursor: pointer" (click)="imageClick(fileserver+image)"/>
      </div>
    </div>
      </p-tabPanel>
      <p-tabPanel header="Upload">
        <p-fileUpload #fileUpload name="file" [auto]="false" mode="advanced" [customUpload]="true" method="post"
          multiple="single" (uploadHandler)="onSend($event, fileUpload)" accept="image/*" maxFileSize="1000000"
          fileLimit="3">
          <ng-template pTemplate="content">
            <ul *ngIf="selectedFiles.length">
              <li *ngFor="let file of selectedFiles">{{file.name}} - {{file.size}} bytes</li>
            </ul>
            <hr />
            <div *ngFor="let file of uploadedFiles; let i = index" class="p-fileupload-files ng-star-inserted">
              <div class="ng-star-inserted">
                <div class="p-fileupload-row ng-star-inserted">
                  <div><img src="{{file.url}}" width="50" class="ng-star-inserted">
                  </div>
                  <div class="p-fileupload-filename">{{file.url}}</div>
                  <!-- <div>{{file.size/1024}} KB</div> -->
                  <span><button type="button" icon="pi pi-times" (click)="remvoe(file.url, i)"
                      class="p-element p-button p-component p-button-icon-only" ng-reflect-icon="pi pi-times"><span
                        class="p-button-icon pi pi-times" aria-hidden="true"></span><span aria-hidden="true"
                        class="p-button-label">&nbsp;</span></button></span>
                  <span>
                    &nbsp;
                    <button type="button" icon="pi pi-clone" (click)="selectdImage(file.url)"
                      class="p-element p-button p-component p-button-icon-only" ng-reflect-icon="pi pi-clone">
                      <span
                        class="p-button-icon pi pi-clone" aria-hidden="true">
                      </span>
                    </button>
                      </span>
                </div>

              </div>
            </div>
          </ng-template>
        </p-fileUpload>
      </p-tabPanel>
    </p-tabView>
  </div>
  <ng-template pTemplate="footer">
    <p-button icon="pi pi-check" (click)="displayBasic=false" label="Ok" styleClass="p-button-text"></p-button>
  </ng-template>
</p-dialog>
