import {Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@services/auth.service';
import {DateTime} from 'luxon';
import { MessageService } from 'primeng/api';

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
    public user;

    constructor(private _auth: AuthService, private router: Router) {}

    ngOnInit(): void {
        //this.user = this.appService.user;
        this.user = {

        }
    }

    logout() {
        
        if(confirm("Are you sure you want to logout?")){
            this._auth.logout();
            setTimeout(()=>{ this.router.navigate(['/login']) }, 2000)
        }
        
    }

    formatDate(date) {
        return DateTime.fromISO(date).toFormat('dd LLL yyyy');
    }
}
