import { AuthService } from '@services/auth.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { DashboardService } from '@services/dashboard.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [DashboardService]
})
export class DashboardComponent implements OnInit, OnDestroy {

  public isLoggedIn = false;

  constructor(private _auth: AuthService, private dashboardService: DashboardService) { }

  dashboardSummary: any = [];
  ngOnInit(): void {
    // this.dashboardService.getDashboardSummary()
    //   .then(response => {
    //     if (response) {
    //       this.dashboardSummary = response;
    //     }
    //     // console.log(response);
    //   });
  }

  // this.isLoggedIn = this._auth.checkCredentials();
  //     let i = window.location.href.indexOf('code');
  //     if(!this.isLoggedIn && i != -1){
  //         this._auth.retrieveToken(window.location.href.substring(i + 5));
  //     }

  ngOnDestroy(): void { }
}