import { API } from "@/const";
import { Component, OnInit } from "@angular/core";
import { AuditorService } from "@services/auditor.service";
import { DashboardService } from "@services/dashboard.service";
import { HelperService } from "@services/helper.service";
import { LoaderService } from "@services/loader.service";
import { log } from "console";
import { MenuItem, MessageService } from "primeng/api";
import { ConfirmationService } from "primeng/api";

@Component({
  selector: "app-workflow-status",
  templateUrl: "./workflow-status.component.html",
  styleUrls: ["./workflow-status.component.scss"],
  providers: [
    MessageService,
    DashboardService,
    AuditorService,
    HelperService,
    ConfirmationService,
  ],
})
export class WorkflowStatusComponent implements OnInit {
  isDownloadingCertificateAuditor = false;
  isDownloadingCertificateFirm = false;
  items: MenuItem[];
  stepActive: "step step-active";
  activeIndex: number = 1;
  auditorStatus: any = {};
  firmStatus: any = {};
  auditor: any = {};
  firmInfo: any;
  auditrId: any;
  auditorCertificate: any;
  firmCertificate: any;
  isValidForDownloadCertificate = false;
  isValidCertificateDownloadedFirm = false;
  isFirmIncharge = false;
  constructor(
    private messageService: MessageService,
    private dashBoard: DashboardService,
    private auditorService: AuditorService,
    private _helper: HelperService,
    private confirmationService: ConfirmationService,
    private loaderService: LoaderService
  ) {}

  ngOnInit() {
    try {
      this.auditorService.getAuditorBasicInfoId().then((res) => {
        if (!this._helper.isEmptyObject(res)) {
          this.auditor = res;
          this.auditrId = res.id;
          this.getAuditorApprpvalStatus(this.auditrId);
        }
      });
    } catch (error) {}
    this.isValidForDownloadCert("AUDITOR");
    this.isValidForDownloadCert("FIRM");
    this.getFirm();
    //  this.getCertificateForAuditor();
  }

  getFirm() {
    try {
      this._helper.getFirmOnly().then((res) => {
        if (!this._helper.isEmptyObject(res)) {
          this.firmInfo = res.data;
          this.getFirmApprpvalStatus(this.firmInfo.id);
        }
      });
    } catch (error) {}
  }

  getAuditorApprpvalStatus(auditorId) {
    // auditorId=126;
    try {
      this.dashBoard.getAuditoorApprovalStatus(auditorId).then((res) => {
        this.auditorStatus = res;
      });
    } catch (error) {}
  }

  getFirmApprpvalStatus(firmId) {
    try {
      this.dashBoard.getFirmApprovalStatus(firmId).then((res) => {
        this.firmStatus = res;
      });
    } catch (error) {}
  }

  getFirmStatus(firmStatus) {
    var status = "Not Enlisted";
    for (const item of firmStatus) {
      if (item.taskStatus == "APPROVED") {
        status = "Enlisted";
      } else {
        status = "Not Enlisted";
      }
    }
    return status;
  }

  //isFirmIncharge
  ///cert/valid_for_download
  isUserHasFirmCertificate(type: any) {
    try {
      this.dashBoard.isUserHasFirmCert(type).then((res) => {
        this.isUserHasFirmCertificate = res.user_has_cert;
      });
      return true;
    } catch (error) {
      console.error("Error occurred while checking validity:", error);
      return false; // Assuming false indicates not valid for download
    }
  }

  isValidForDownloadCert(type: any) {
    try {
      this.dashBoard.isValidForDownloadCert(type).then((res) => {
        if(type == 'AUDITOR'){
          this.isValidForDownloadCertificate = res.isValidForDownloadCertificate;
        }else{
          this.isValidCertificateDownloadedFirm = res.isValidForDownloadCertificate;
        }
      });
      return true;
    } catch (error) {
      console.error("Error occurred while checking validity:", error);
      return false; // Assuming false indicates not valid for download
    }
  }

  getCertificateForAuditor() {
    let type = "AUDITOR";
    try {
      if (!this.isValidForDownloadCertificate) {
        this.confirmationService.confirm({
          message: `
            <div>
              <p>
                <strong>Important: Payment Required for Certificate Download</strong>
              </p>
              <p>
                Before downloading the certificate, you must complete the payment of <strong>1150 tk</strong>.
              </p>
              <p>
                <em>Please note:</em>
              </p>
              <ul>
                <li>Each payment allows <strong>one-time download</strong> of the certificate.</li>
                <li>You cannot download the certificate multiple times with the same payment.</li>
              </ul>
            </div>
            <p>Do you want to proceed with payment?</p>
          `,
          header: "Certificate Download",
          acceptLabel: "Proceed with Payment",
          rejectLabel: "Cancel",
          accept: () => {
            // Proceed with payment
            // You can redirect the user to the payment page or handle the payment process here
            let purpose_code = this.auditor.professionalTitle + "_FEE";
            this.processOnlinePayment(purpose_code, "CERTIFICATE_AUDITOR");
            console.log("Redirecting to payment page...");
          },
          reject: () => {
            // Do nothing or provide alternative action
            console.log("User declined to proceed with payment.");
          },
          rejectButtonStyleClass: "p-button-outlined", // Set custom CSS class for the cancel button
          acceptButtonStyleClass: "p-button-primary", // Set custom CSS class for the cancel button
        });
      } else {
        this.isDownloadingCertificateAuditor = true;
        // User is valid for download, proceed with downloading the certificate
        this.dashBoard.downloadPDF(type).subscribe(
          (response: Blob) => {
            const blob = new Blob([response], { type: "application/pdf" });
            const downloadLink = document.createElement("a");
            const url = URL.createObjectURL(blob);

            downloadLink.href = url;
            downloadLink.download = "FRC_Enlistment_Certificate.pdf"; // Set the desired file name
            downloadLink.click();
            // window.open(url, '_blank', 'noopener,noreferrer');
            URL.revokeObjectURL(url);
            this.isDownloadingCertificateAuditor = false;
          },
          (error) => {
            console.error("Error downloading PDF:", error);
            this.isDownloadingCertificateAuditor = false;
          }
        );
        this.isValidForDownloadCertificate = false;
      }
    } catch (error) {
      console.error("An error occurred while processing the request:", error);
      // Handle error (e.g., display an error message)
    }
  }

  processOnlinePayment(purpose_code, payment_type) {
    var data = {
      total_amount: 1150,
      purpose: "Certificate fee",
      purpose_code: purpose_code,
      redirect_url: API.REDIRECT_DASHBOARD,
      payment_type: payment_type,
    };

    this.auditorService.onlinePay(data).then((res) => {
      if (!this._helper.isEmptyObject(res)) {
        window.location.href = res["data"];
      }
    });
  }

  getCertificateForFirm() {
    let type = "FIRM";
    try {
      if (!this.isValidCertificateDownloadedFirm) {
        this.confirmationService.confirm({
          message: `
            <div>
              <p>
                <strong>Important: Payment Required for Certificate Download</strong>
              </p>
              <p>
                Before downloading the certificate, you must complete the payment of <strong>1150 tk</strong>.
              </p>
              <p>
                <em>Please note:</em>
              </p>
              <ul>
                <li>Each payment allows <strong>one-time download</strong> of the certificate.</li>
                <li>You cannot download the certificate multiple times with the same payment.</li>
              </ul>
            </div>
            <p>Do you want to proceed with payment?</p>
          `,
          header: "Certificate Download",
          acceptLabel: "Proceed with Payment",
          rejectLabel: "Cancel",
          accept: () => {
            // Proceed with payment
            // You can redirect the user to the payment page or handle the payment process here
            let purpose_code = this.firmInfo.professionalTitle + "_FEE";
            this.processOnlinePayment(purpose_code, "CERTIFICATE_FIRM");
            console.log("Redirecting to payment page...");
          },
          reject: () => {
            // Do nothing or provide alternative action
            console.log("User declined to proceed with payment.");
          },
          rejectButtonStyleClass: "p-button-outlined", // Set custom CSS class for the cancel button
          acceptButtonStyleClass: "p-button-primary", // Set custom CSS class for the cancel button
        });
      } else {
        this.isDownloadingCertificateFirm = true;
        // User is valid for download, proceed with downloading the certificate
        this.dashBoard.downloadPDF(type).subscribe(
          (response: Blob) => {
            const blob = new Blob([response], { type: "application/pdf" });
            const downloadLink = document.createElement("a");
            const url = URL.createObjectURL(blob);

            downloadLink.href = url;
            downloadLink.download = "FRC_Enlistment_FIRM_Certificate.pdf"; // Set the desired file name
            downloadLink.click();
            // window.open(url, '_blank', 'noopener,noreferrer');
            URL.revokeObjectURL(url);
            this.isDownloadingCertificateFirm = false;
          },
          (error) => {
            console.error("Error downloading PDF:", error);
            this.isDownloadingCertificateFirm = false;
          }
        );
        this.isValidCertificateDownloadedFirm = false;
      }
    } catch (error) {
      console.error("An error occurred while processing the request:", error);
      // Handle error (e.g., display an error message)
    }
  }

  areAllItemsApprovedForAuditor(): boolean {
    return this.auditor.frcId !=null;
    // return true
  }
  areAllItemsApprovedForFirm(): boolean {
    // if (!Array.isArray(this.firmStatus)) {
    //   console.log("firmStatus is not an array");
    //   return false;
    // }
    // return this.firmStatus.every((item) => item.taskStatus === "APPROVED");
   
    return this.firmInfo.firmRegId !=null;
  }
}
// $2a$10$sWh96CFbMonjgtD8pFBKjOULcBnFwKhkDqwWD1ly60rqdymYihCaS  01938884442

// $2a$10$F4N4Y9IQipZb4.cm2xM/QOhWTiITVw9w0jJXvbba5WpvArX229OSC     towru
