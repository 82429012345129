import { Component, OnInit } from '@angular/core';
import { Cookie } from 'ng2-cookies';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})

export class ProfileComponent implements OnInit {

    constructor() { }

    user: any = [];
    ngOnInit(): void {
        this.getMe();
    }
    getPhotoUrl(url){
        let defaultUrl = 'assets/img/default-profile.png';
        return url? url: defaultUrl;
    }

    getMe(){
        this.user = JSON.parse(Cookie.get('user'));
    } catch (e) {
      console.log("Invalid User Data");
    }

    updatePhoto(e: Event){
        
    }
}


