import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from '@/const'

@Injectable()
export class DashboardService {

    constructor(private http: HttpClient) { }

    getDashboardSummary() {
        return this.http.get<any>(API.BASE_API_URL + API.DASHBOARD_SUMMARY)
            .toPromise()
            .then(res => <any>res)
            .then(data => { return data; });
    }
    getAuditoorApprovalStatus(id: any) {
        return this.http.get<any>(API.BASE_WORKFLOW_URL + API.GET_AUDITOR_APPROVAL_STATUS + id)
          .toPromise()
          .then(res => <any>res)
          .then(data => { return data; });
      }
      getFirmApprovalStatus(id: any) {
        return this.http.get<any>(API.BASE_WORKFLOW_URL + API.GET_FIRM_APPROVAL_STATUS + id)
          .toPromise()
          .then(res => <any>res)
          .then(data => { return data; });
      }
      getCertificate(type: any) {
        return this.http.get<any>(API.BASE_API_URL + API.GET_CERTIFICATE + type)
          .toPromise()
          .then(res => <any>res)
          .then(data => { return data; });
      }

      isValidForDownloadCert(type: any) {
        return this.http.get<any>(API.BASE_API_URL + API.GET_IS_VALID_FOR_DOWNLOAD_CERTIFICATE + type)
          .toPromise()
          .then(res => <any>res)
          .then(data => { return data; });
      }

      isUserHasFirmCert(type: any) {
        return this.http.get<any>(API.BASE_API_URL + API.GET_IS_VALID_FOR_FIRM_CERT + type)
          .toPromise()
          .then(res => <any>res)
          .then(data => { return data; });
      }

      downloadPDF(type: any) {
        const url = API.BASE_API_URL + API.GET_CERTIFICATE + type; // Replace with your API endpoint
      
        return this.http.get(url, { responseType: 'blob' });
      }
}