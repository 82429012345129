import { AuthConfig } from '@/sso.config';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Cookie } from 'ng2-cookies';

const TOKEN_KEY = 'auth-token';
const USER_KEY = 'auth-user';
const ROLE = 'role';
const AGENCY_ID = 'agency_id';
const AGENCY_NAME = 'agency_name';
const LAT = 'lat';
const LON = 'lon';
const COUNTRYCODE = 'country_code';


@Injectable({
  providedIn: 'root'
})
export class StorageService {
    constructor(private _http: HttpClient) { }

  logout(): void {
    window.localStorage.clear();
    window.sessionStorage.clear();
    Cookie.deleteAll();
    // this._http.get('http://localhost:9000/logout')
    //   .toPromise()
    //   .then(res => <any>res)
    //   .then(data => { return data; })
    //   .then(res=>{

    //   })
    window.location.href = 'https://auth.frc.gov.bd/logout';
  }

  public saveToken(token: string): void {
    window.localStorage.removeItem(TOKEN_KEY);
    window.localStorage.setItem(TOKEN_KEY, token);
  }

  public getToken(): string | null {
    return window.localStorage.getItem(TOKEN_KEY);
  }

  public saveUser(user: any): void {
    window.localStorage.removeItem(USER_KEY);
    window.localStorage.setItem(USER_KEY, JSON.stringify(user));
  }
  public saveUserRole(role: any): void {
    window.localStorage.removeItem(ROLE);
    window.localStorage.setItem(ROLE, JSON.stringify(role));
  }
  public getUserRole(): any {
    return window.localStorage.getItem(ROLE);
  }
  public saveAgencyId(user: any): void {
    //window.localStorage.removeItem(AGENCY_ID);
    window.localStorage.setItem(AGENCY_ID, user);
  }
  public getAgencyId() {
    return window.localStorage.getItem(AGENCY_ID);
  }
  public saveAgencyName(user: any): void {
    //window.localStorage.removeItem(AGENCY_NAME);
    window.localStorage.setItem(AGENCY_NAME, user);
  }
  public getAgencyName() {
    return window.localStorage.getItem(AGENCY_NAME);
  }
  public saveAgencyCountryCode(countryCode: any,): void {
    window.localStorage.removeItem(COUNTRYCODE);
    window.localStorage.setItem(COUNTRYCODE, JSON.stringify(countryCode));
  }
  public getAgencyCountryCode(): any {
    return window.localStorage.getItem(COUNTRYCODE);
  }

  public saveUserLat(currentLatitude: any,): void {
    window.localStorage.removeItem(LAT);
    window.localStorage.setItem(LAT, JSON.stringify(currentLatitude));
  }
  public saveUserLon(currentLongitude: any): void {
    window.localStorage.removeItem(LON);
    window.localStorage.setItem(LON, JSON.stringify(currentLongitude));
  }
  public getLat(): any {
    return window.localStorage.getItem(LAT);
  }
  public getLon(): any {
    return window.localStorage.getItem(LON);
  }

  public getCurrentUser(): any {
    const user = window.localStorage.getItem(USER_KEY);
    if (user) {
      return JSON.parse(user);
    }

    return {};
  }

  public getKey(key: string): string | null {
    return window.localStorage.getItem(key);
  }

  public setKey(key: string, value: string) {
    window.localStorage.setItem(key, value);
  }

  public removeKey(key: string) {
    window.localStorage.removeItem(key);
  }


}
function HostListener(arg0: string, arg1: string[]) {
  throw new Error('Function not implemented.');
}

