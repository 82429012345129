import { API } from '@/const';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.scss']
})
export class FileComponent implements OnInit {
  @Input() label: string = 'File Input';
  @Input() lblon: boolean = true;
  @Input() accept: string = 'application/pdf';
  @Output() fileChange = new EventEmitter<string>();
  @Output() fileDelete = new EventEmitter<void>();
  @Input() neededPreview: boolean;
  @Input() neededDelete: boolean;
  @Input() lockedDelete: boolean;
  @Input() neededRefresh: boolean;
  @Input() disabled: boolean;
  @Input() loading: boolean;
  defaultMessage: string = 'Choose file'
  file: File;
  @Input() url: string;

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    this.buildDefaultMessage();

  }

  ngOnChanges() {
    if(this.isValidHttpUrl(this.url)){
      this.neededDelete = true;
      this.neededPreview = true;
      this.defaultMessage = new URL(this.url).pathname.split("/").pop();
      console.log(this.defaultMessage);
    }else{
      //console.log(this.defaultMessage);

    }
    if(this.neededRefresh){
        this.loading = false;
        this.neededDelete = false;
        this.neededPreview = false;
        this.url = undefined;
        this.buildDefaultMessage();
    }
  }

  isValidHttpUrl(string) {
    let url;
    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
  }


  buildDefaultMessage(){
    if(this.url == undefined){
      this.defaultMessage = 'Choose '+ this.accept.substring(this.accept.lastIndexOf('/')+1)
    }
  }

  upload(event: any){
    let files =  event.target.files;

    if(files.length == 1){
       this.file = files[0];
      this.defaultMessage = this.file.name;
      this.loading = true;
      this.fileUpload(this.file)
      .then(data =>{
        this.loading = false;
        this.url = data.url;
        this.fileChange.emit( data.url);
        this.neededDelete = true;
        this.neededPreview = true;
      }).catch(e=> this.loading = false)
    }

  }

  delete(){
    if(this.lockedDelete){
      alert('Please note that it is not possible to delete anything after submitting the form. If you require any assistance or have any questions, please contact our support team.');
      return;
    }
    let isNeededDelete = confirm('Are you sure you want to remove this file?')
    if(isNeededDelete){
      let filename = new URL(this.url).pathname.split("/").pop();
      this.url = undefined;
      this.buildDefaultMessage();
      this.fileDeleteSrv(filename);
    }
  }

  fileUpload(file: File) {
    const formData = new FormData();
    formData.append('file', file);
   return  this.http
      .post<any>(API.BASE_API_URL + API.SAVE_FILE, formData)
      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });
  }

  fileDeleteSrv(filename: string) {

    this.loading = true;
   return  this.http
      .delete<any>(API.BASE_API_URL + API.DELETE_FILE+'/'+filename)
      .toPromise()
      .then(res => <any>res)
      .then(data => {
        this.loading = false;
        this.fileDelete.emit();
        this.neededDelete = false;
        this.neededPreview = false;
        this.url = '';
        this.buildDefaultMessage();
      }).catch(err=>{
        this.loading = false;
        this.fileDelete.emit();
      })
  }

}
