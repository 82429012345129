import {HttpClient} from '@angular/common/http';
import {HelperService} from './../../../../services/helper.service';
import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {MessageService} from 'primeng/api';
import {API} from '@/const';

@Component({
    selector: 'app-file-manager',
    templateUrl: './file-manager.component.html',
    styleUrls: ['./file-manager.component.scss'],
    providers: [MessageService, HelperService]
})
export class FileManagerComponent implements OnInit {
    @Input() label: string = "Choose image";
    @Output() selectdOneImage = new EventEmitter<string>();

    @ViewChild('fileUpload') fileUpload: any;
    fileserver: string = API.BASE_URL_file;
    displayBasic: boolean;
    displayModal: boolean;
    images: any[];

    selectedFiles: any[] = [];
    uploadedFiles: any[] = [];

    constructor(
        private messageService: MessageService,
        private _helper: HelperService,
        private http: HttpClient
    ) {}

    ngOnInit(): void {
        this.getImages();
    }

    clear() {
        this.fileUpload.clear();
    }

    showBasicDialog() {
        this.displayBasic = true;
    }

    getImages() {
        this.http
            .get(API.BASE_API_URL + API.get_FILES)
            .toPromise()
            .then((res) => <any[]>res)
            .then((data) => {
                this.images = data;
            })
            .catch((err) => console.log(err));
    }

    imageClick(imageId: string) {
      this.selectdOneImage.emit(imageId);
      this.displayBasic = false;
    }

    onSend(event, form) {
        let formData = new FormData();
        for (const file of event.files) {
            formData.append('file', file);
        }

        this.http
            .post<any>(API.BASE_API_URL + API.UPLOAD_FILES, formData)
            .toPromise()
            .then((res) => <any[]>res)
            .then((data) => {
                this.messageService.add({
                    severity: 'success',
                    summary: 'Your file uploaded successfully',
                    detail: ''
                });
                this.uploadedFiles = [data];
                this.selectedFiles = [];
                
            })
            .catch((err) => console.log(err));

        form.clear();
    }

    remvoe(imageUrl, index) {
        let imageName = imageUrl.substr(imageUrl.lastIndexOf("/")+1)
        this.http
            .delete<any>(API.BASE_API_URL + API.UPLOAD_FILE_DELETE +'/'+imageName)
            .toPromise()
            .then((data) => {
                this.messageService.add({
                    severity: 'success',
                    summary: 'Your file deleted successfully',
                    detail: ''
                });
                this.uploadedFiles.splice(index, 1);
            })
            .catch((err) => console.log(err));
    }

    selectdImage(value: string) {
        this.selectdOneImage.emit(value);
        this.displayBasic = false;
    }
}
