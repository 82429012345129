
import {
    Component,
    OnInit,
    OnDestroy,
    Renderer2,
    HostBinding
} from '@angular/core';
import {UntypedFormGroup, UntypedFormControl, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {AppService} from '@services/app.service';
import { AuthService } from '@services/auth.service';
import { StorageService } from '@services/storage.service';
import { Router } from '@angular/router';
import { AuthConfig as authconfig } from '@/sso.config';
import { Config } from '@/const';
import { HelperService } from '@services/helper.service';
@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
    //@HostBinding('class') class = 'login-box';
    submitted = false;
    public loginForm: UntypedFormGroup;
    public isAuthLoading = false;
    public isGoogleLoading = false;
    public isFacebookLoading = false;

    public isLoggedIn = false;
    public Logo='';

    constructor(
        private renderer: Renderer2,
        private toastr: ToastrService,
        private appService: AppService,
        private storageService: StorageService,
        private router: Router,
        private _auth: AuthService,
        private helpler: HelperService
        
    ) {}

    ngOnInit() {
        this.Logo=Config.frcLogo;
      //  this.Logo=Config.icabLogoUrl;
       
        // this.renderer.addClass(
        //     document.querySelector('app-root'),
        //     'login-page'
        // );
        this.loginForm = new UntypedFormGroup({
        });
        //
        // debugger;
        this.isLoggedIn = this._auth.checkCredentials();
        let i = window.location.href.indexOf('code');
        if (!this.isLoggedIn && i != -1) {
            this._auth.retrieveToken(window.location.href.substring(i + 5));            
        }else{
            this.router.navigate(['/']);
        }
    }

    get f() {
      return this.loginForm.controls;
    }

    async loginByAuth() {
        if (this.loginForm.valid) {
            this.isAuthLoading = true;
            //await this.appService.loginByAuth(this.loginForm.value);
            this.isAuthLoading = false;
        } else {
            this.toastr.error('Form is not valid!');
            // this.isAuthLoading = true;
        }
    }
    loginByFRCAuthenticationSystem(){
      let issuer = authconfig.issuer;
      let clientId = authconfig.clientId;
      let responseType = authconfig.responseType;
      let scope = authconfig.scope;
      let redirectUri = authconfig.redirectUri;

      window.location.href = authconfig.login_url;
    }

    onSubmit() {
      
    }

    loginByICAB(){
    }


    ngOnDestroy() {
        this.renderer.removeClass(
            document.querySelector('app-root'),
            'login-page'
        );
    }
}


function delay(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
