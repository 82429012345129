<p-dialog header="Enable Menu" [(visible)]="displayModal" [modal]="true" [style]="{width: '40vw'}"
    [draggable]="false" [resizable]="false">
    <div class="row">
        <div class="col-md-8">
            <h2 class="m-0 bold">1. Do you want submit Auditor enlistment form? </h2>
        </div>
        <div class="col-md-4 float-right d-flex justify-content-end">
            <p-toggleButton onLabel="Yes" offLabel="No" 
            onIcon="pi pi-check" offIcon="pi pi-times" iconPos="right" [(ngModel)]="checkedAuditor"></p-toggleButton>
        </div>        
    </div>
    <div class="row mt-2">
        <div class="col-md-8">
            <h2 class="m-0 bold">2. Do you want submit Firm enlistment form? </h2>
        </div>
        <div class="col-md-4 float-right d-flex justify-content-end">
            <p-toggleButton onLabel="Yes" offLabel="No" 
            onIcon="pi pi-check" offIcon="pi pi-times" iconPos="right" [(ngModel)]="checkedFirm"></p-toggleButton>
        </div>
    </div>
        
        
        <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (click)="UpdateSetting()" label="Save" styleClass="p-button-text"></p-button>
        </ng-template>
</p-dialog>
