<header>
  <nav class="navbar navbar-expand-lg navbar-light bg-light custom-background p-0">
   <div class="container">
     <!-- Nav Toggle Button -->
     <button class="navbar-toggler my-2" type="button" data-bs-toggle="collapse" data-bs-target="#main_nav"
     aria-expanded="false" aria-label="Toggle navigation">
     <span class="navbar-toggler-icon" (click)="toggleClass()"></span>
   </button>
   <!-- Nav Links -->
   <div class="collapse navbar-collapse lh-lg" [class.show]="shouldAddClass" id="main_nav">
     <a class="navbar-brand my-1" href="/home"><img src="/assets/img/frc.png" height="40px" class="mr-2" /></a>
     <ul class="navbar-nav p-3 p-md-0">
       <li class="nav-item"> <a class="nav-link " style="font-weight: bold;" href="/">Home</a> </li>
       <li class="nav-item dropdown">
         <a class="nav-link dropdown-toggle" (click)="toggleSubClass()" style="font-weight: bold;" data-bs-toggle="dropdown"> Enlisted Audit Firm
         </a>
         <!-- Dropdown -->
         <ul class="dropdown-menu" [class.show]="shouldAddClassSub">
           <li><a class="dropdown-item"   (click)="enlistedAuditFirmCA()">Enlisted Audit Firm (CA)</a></li>
           <!-- Dropdown Megasubmenu -->

           <li><a class="dropdown-item" (click)="enlistedAuditFirmFCMA()">Enlisted Audit Firm (CMA) </a></li>
         </ul>
       </li>
       <li class="nav-item dropdown" >
         <a class="nav-link dropdown-toggle" (click)="toggleSub2Class()" style="font-weight: bold;" data-bs-toggle="dropdown"> Enlisted Auditors
         </a>
         <!-- Dropdown -->
         <ul class="dropdown-menu" [class.show]="shouldAddClassSub2">
           <li><a class="dropdown-item" (click)="enlistedAuditFirmCAwithCOP()">Auditors (CA With CoP)</a></li>
           <!-- Dropdown Megasubmenu -->

           <li><a class="dropdown-item" (click)="enlistedAuditFirmCAwithOutCOP()">Auditors (CA Without CoP) </a></li>
           <li><a class="dropdown-item" (click)="enlistedAuditFirmCMAwithCOP()">Auditors (CMA With CoP) </a></li>
           <li><a class="dropdown-item" (click)="enlistedAuditFirmCMAwithOutCOP()">Auditors (CMA Without CoP) </a></li>
         </ul>
       </li>
       <!-- Tree Menu -->

       <li class="nav-item" style="position:absolute; right:80px;">
         <a class="nav-link btn" style="font-weight: bold;" href="/login">Signup/Login</a>
       </li>
     </ul>
   </div>
   </div>
  </nav>
</header>

<div class=" mar">

  <section>

      <div class="container" *ngIf="bodyShow">
        <div class="row mt-5 justify-content-center align-items-center">
          <div class="col-md-12">
            <div class="search-container">
              <div class="search-type">
                <label for="searchType" class="form-label" style="margin-right: 10px;">Search Type:</label>
                <select id="searchType" (change)="changeType($event.target.value)" class="form-select">
                  <option value="1" [selected]="selectedType === '1'">Auditor</option>
                  <option value="2" [selected]="selectedType === '2'">Firm</option>
                </select>
              </div>
              <div class="search-input mt-1">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    id="searchInput"
                    (input)="changeText($event.target.value)"
                    (keydown.enter)="searchKeyword()"
                    placeholder="Search Audit Firm / Auditor"
                  />
                  <button class="btn btn-primary" (click)="searchKeyword()">Search</button>
                </div>
              </div>
            </div>
            <div class="search-results mt-4">
              <div *ngIf="selectedType === '1'">
                <h2 class="section-title">Auditors</h2>
                <div class="auditor-cards">
                  <div class="auditor-card" *ngFor="let item of searchData">
                    <div class="auditor-header">
                      <h3>
                        <i class="fas fa-user"></i> <!-- Font Awesome user icon -->
                        {{ item.auditorName }} ({{ item.privId }} )
                      </h3>
                      <p>{{ item.auditorType === 'CA' ? 'Chartered Accountant' : 'Cost & Management Accountant' }}</p>
                    </div>
                    <div class="auditor-details">
                      <p><strong>Firm Name:</strong> {{ item.firmName }}</p>
                      <p><strong>FRC Enlistment Number:</strong> {{ item.auditorFrcId }}</p>
                      <p><strong>Firm Nature:</strong> {{ item.firmType | titlecase }}</p>
                      <p><strong>Role:</strong> {{ item.firmRole | titlecase }}</p>
                    </div>
                    <hr class="card-divider" />
                  </div>
                </div>
              </div>
              <div *ngIf="selectedType === '2'">
                <h2 class="section-title">Firms</h2>
                <div class="firm-cards">
                  <div class="firm-card" *ngFor="let item of searchData">
                    <div class="firm-header">
                      <h3>
                        <i class="fas fa-building"></i>
                        {{ item.firmName }}</h3>
                      <p>{{ item.firmType }}</p>
                    </div>
                    <div class="firm-details">
                      <p><strong>FRC Enlistment Number:</strong> {{ item.firmRegId }}</p>
                      <button class="btn btn-outline-info" (click)="viewAuditors(item.auditors)">
                        Show Enlisted Proprietor/Partners
                      </button>
                    </div>
                    <hr class="card-divider" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      



  </section>


  <section class="content-header" *ngIf="firmShow">
    <div class="container">
      <div class="row mb-1">
        <div class="col-md-12 col-sm-12">
          <p-card>
            <div class="row">
              <div class="col-md-12">
                <p-table #dt1 [value]="publishedListForFirm" [scrollable]="true" scrollHeight="530px"
                  [loading]="isLoading" [tableStyle]="{'min-width': '70rem' ,'height':'200rem'}"
                  [globalFilterFields]="['firmName', 'firmFrcId']">
                  <ng-template pTemplate="caption">
                    <div class="row">
                      <div class="col-md-3" style="text-align: left;;">
                        <h1>Enlisted Firms</h1>
                      </div>
                      <div class="col-md-5"></div>
                      <div class="col-md-4" style="text-align: right;">
                        <span class="p-input-icon-right ml-auto">
                          <input pInputText type="text" (input)="dt1.filterGlobal($event.target.value, 'contains')"
                            placeholder="Search keyword" />
                          <i class="pi pi-search"></i>
                        </span>

                      </div>

                    </div>
                  </ng-template>
                  <ng-template pTemplate="header">
                    <tr>
                      <th>Sl No.</th>
                      <th>Firm Name</th>
                      <th>FRC Enlistment Number</th>
                      <th>Firm Nature</th>
                      <th>Enlisted Proprietor/Partners</th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-publishedListForFirm let-i="rowIndex">
                    <tr [pSelectableRow]="publishedListForFirm">
                      <td>{{i+1}}</td>
                      <td>{{publishedListForFirm.firmName | titlecase}}</td>
                      <td>{{publishedListForFirm.firmRegId}}</td>
                      <td>{{publishedListForFirm.type | titlecase}}</td>
                      <td>
                        <a class="btn btn-default" (click)="viewAuditors(publishedListForFirm.auditors)">Show</a>
                      </td>

                    </tr>
                  </ng-template>
                </p-table>

              </div>
            </div>
          </p-card>
        </div>
      </div>
    </div>

    

  </section>
  <p-dialog header="Enlisted Proprietor/Partners" [(visible)]="visibleFirmAuditorDialog" 
  (onHide)="onDialogHide()">
  <div class="flex py-2 justify-content-center">
    <table class="table table-bordered">
      <thead>
        <tr>
          <th>SL#</th>
          <th>Name</th>
          <th>FRC Enlistment Number</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of viewedAuditors; let i= index">
          <td>{{i+1}}</td>
          <td>{{item.auditorName}}</td>
          <td>{{item.auditorFrcId}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</p-dialog>


  <section class="content-header" *ngIf="AuditorShow">

    <div class="container">
      <div class="row mb-1">
        <div class="col-md-12 col-sm-12">
          <p-card>
            <div class="row">
              <div class="col-md-12">
                <p-table #dt1 [value]="publishedListForAuditor" [scrollable]="true" scrollHeight="530px"
                  [loading]="isLoading" [tableStyle]="{'min-width': '70rem' ,'height':'200rem'}"
                  [globalFilterFields]="['auditorName', 'auditorFrcId', 'privId']">
                  <ng-template pTemplate="caption">
                    <div class="row">
                      <div class="col-md-3" style="text-align: left;">
                        <h1>Enlisted Auditors</h1>
                      </div>
                      <div class="col-md-5"></div>
                      <div class="col-md-4" style="text-align: right;">
                        <span class="p-input-icon-right ml-auto">
                          <input pInputText type="text" (input)="dt1.filterGlobal($event.target.value, 'contains')"
                            placeholder="Search keyword" />
                          <i class="pi pi-search"></i>
                        </span>
                      </div>
                    </div>
                  </ng-template>
                  <ng-template pTemplate="header">
                    <tr>
                      <th>Sl No.</th>
                      <th style="text-align: left;">Name</th>
                      <th>FRC Enlistment Number</th>
                      <th>{{ORG}} Enrolment Number</th>
                      <th>Role</th>
                      <th>Firm Name</th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-publishedListForAuditor let-i="rowIndex">
                    <tr [pSelectableRow]="publishedListForAuditor">
                      <td>{{i+1}}</td>
                      <td style="text-align: left;">{{publishedListForAuditor.auditorName | titlecase}}</td>
                      <td style="text-align: center;">{{publishedListForAuditor.auditorFrcId}}</td>
                      <td style="text-align: center;">{{publishedListForAuditor.privId}}</td>
                      <td style="text-align: center;">{{publishedListForAuditor.firmRole | titlecase}}</td>
                      <td>{{publishedListForAuditor.firmName | titlecase}}</td>
                    </tr>
                  </ng-template>
                </p-table>

              </div>
            </div>
          </p-card>
        </div>
      </div>
    </div>

  </section>

</div>

<div class="container-fluid footer">
  <!-- <app-footer></app-footer> -->
</div>