import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from '@modules/main/main.component';
import { BlankComponent } from '@pages/blank/blank.component';
import { LoginComponent } from '@modules/login/login.component';
import { ProfileComponent } from '@pages/profile/profile.component';
import { DashboardComponent } from '@pages/dashboard/dashboard.component';
import { AuthGuard } from '@guards/auth.guard';
import { NonAuthGuard } from '@guards/non-auth.guard';
import { ForgotPasswordComponent } from '@modules/forgot-password/forgot-password.component';
import { RecoverPasswordComponent } from '@modules/recover-password/recover-password.component';
import { PrivacyPolicyComponent } from '@modules/privacy-policy/privacy-policy.component';
import { RegistrationComponent } from '@modules/authentication/registration/registration.component';
import { EmailVerifyComponent } from '@modules/authentication/email-verify/email-verify.component';
import { MenutabComponent } from '@modules/auditor/reg/menutab/menutab.component';
import { MobileComponent } from '@modules/authentication/registration/mobile/mobile.component';
import { PasswordComponent } from '@modules/authentication/registration/password/password.component';
import { EnablemenuComponent } from '@modules/main/enablemenu/enablemenu.component';
import { EnlistmentListComponent } from '@modules/elist/enlistment-list/enlistment-list.component';
import { PublicHomeComponent } from '@pages/public-home/public-home.component';

const routes: Routes = [
    {
        path: 'login',
        component: LoginComponent,
    },
    {
        path: 'home',
        component: EnlistmentListComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: '',
        component: MainComponent,
        canActivateChild: [AuthGuard],
        data: {roles: ["ROLE_GUEST", "ROLE_AUDITOR", "ROLE_PARTNER", "ROLE_SYSTEM", "ROLE_FRC_ADMIN"]},
        children: [
            {
                path: 'profile',
                component: ProfileComponent
            },
            {
                path: 'enable-menu',
                component: EnablemenuComponent,
                canActivate: [NonAuthGuard],
            },
            {
                path: 'blank',
                component: BlankComponent
            },
            {
                path: '',
                component: DashboardComponent
            }
        ]
    },
    
    {
        path: 'registration',
        component: RegistrationComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'mobile-verify',
        component: MobileComponent,
        canActivate: [NonAuthGuard]
    },

    {
        path: 'password-setup',
        component: PasswordComponent,
        canActivate: [NonAuthGuard]
    },

    {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'recover-password',
        component: RecoverPasswordComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'email-verify',
        component: EmailVerifyComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'privacy-policy',
        component: PrivacyPolicyComponent,
        canActivate: [NonAuthGuard]
    },
    
    { path: 'auditor', canActivate:[AuthGuard], loadChildren: () => import('./modules/auditor/auditor.module').then(m => m.AuditorModule) },
    { path: 'firm', loadChildren: () => import('./modules/firm/firm.module').then(m => m.FirmModule) },
    { path: 'frc', loadChildren: () => import('./modules/frc/frc.module').then(m => m.FRCModule) },
    { path: 'elist', loadChildren: () => import('./modules/elist/elist.module').then(m => m.ElistModule) },
    { path: '**', redirectTo: ''}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
