import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from '@/const'

@Injectable()
export class UserService {

  constructor(private http: HttpClient) { }

  getUsers() {
    return this.http.get<any>(API.BASE_URL_KYC + API.FIND_ALL_USERS)
      .toPromise()
      .then(res => <any[]>res)
      .then(data => { return data; });
  }

  getUsersByToken() {
    return this.http.get<any>(API.BASE_URL_KYC + API.FIND_USER_BY_TOKEN)
      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });
  }

  
}
