import { AppState } from '@/store/state';
import { UiState } from '@/store/ui/state';
import { Component, HostBinding, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppService } from '@services/app.service';
import { Observable } from 'rxjs';
import { Config } from '@/const';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from '@services/auth.service';
import { UserService } from '@services/user.service';
import { Cookie } from 'ng2-cookies';
import { HelperService } from '@services/helper.service';

const BASE_CLASSES = 'main-sidebar elevation-4';
@Component({
  selector: 'app-menu-sidebar',
  templateUrl: './menu-sidebar.component.html',
  styleUrls: ['./menu-sidebar.component.scss'],
  providers: [UserService]
})
export class MenuSidebarComponent implements OnInit {
  @HostBinding('class') classes: string = BASE_CLASSES;
  public ui: Observable<UiState>;
  public menu = [];
  public Logo = '';
  user: any = {};
  renewalStatus: any={};
  //enlistedAuditor  hasEnlistedFirm
  constructor(
    public appService: AppService,
    private store: Store<AppState>,
    private tokenVerify: JwtHelperService,
    private _auth: AuthService,
    private userService: UserService,
    private helper: HelperService
  ) { }

  ngOnInit() {
    this.Logo = Config.frcLogo;
    this.ui = this.store.select('ui');
    this.ui.subscribe((state: UiState) => {
      this.classes = `${BASE_CLASSES} ${state.sidebarSkin}`;
    });

    this.menuFilterByRole();
    this.getMe();
    
  }

  getMe() {
    try {
      this.user = JSON.parse(Cookie.get('user'));
      // this.renewalStatus= JSON.parse(Cookie.get('renewalData'));
    } catch (e) {
      this.helper.getMe().then(res => {
        let data = res.data;
        if(data){
          this.user = {name: data.name, mobile: data.mobile, email: data.email, photoUrl: data.photoUrl, roles: data.roles, enlistedAuditor: data.enlistedAuditor, hasEnlistedFirm:data.hasEnlistedFirm}
          // this.renewalStatus={enlistedAuditor:data.enlistedAuditor, hasEnlistedFirm:data.hasEnlistedFirm}
          Cookie.set('user', JSON.stringify(this.user));
          // Cookie.set('enlistedAuditor', JSON.stringify(this.renewalStatus));
        }
      })
    }
  }
  menuFilterByRole() {
    let roles = this.tokenVerify.decodeToken(this._auth.getTokenFromCookie()).authorities;
    this.menu = this.MENU.filter(m => {
      for (const el of m.roles) {
        if (roles.includes(el)) {
          return true;
        }
      }

      return false;
    });

  }

  MENU = [
    {
      code: '101',
      name: this.user.enlistedAuditor ? 'AuditorRenewal' : 'Auditor Registration',
      icon: 'fas fa-tachometer-alt',
      isRenewal: this.user.enlistedAuditor,
      path: ['/auditor/auditor-enlistment-criteria'],
      roles: ["ROLE_GUEST", "ROLE_AUDITOR", "ROLE_PARTNER"]
    },
    {
      code: '102',
      name: this.user.hasEnlistedFirm ? 'Firm Renewal': 'Firm Registration',
      isRenewal: this.user.hasEnlistedFirm,
      icon: 'fas fa-tachometer-alt',
      path: ['/firm/enlistment'],
      roles: ["ROLE_GUEST", "ROLE_AUDITOR", "ROLE_PARTNER", "ROLE_FIRM_MANAGER"]
    },
    {
      code: '103',
      name: 'Auditor Management',
      icon: 'fas fa-tachometer-alt',
      isRenewal: false,
      path: ['/frc/auditor-management'],
      roles: ["ROLE_FRC_ADMIN", "ROLE_SYSTEM"]
    },
    {
      code: '104',
      name: 'Firm Management',
      icon: 'fas fa-tachometer-alt',
      isRenewal: false,
      path: ['/frc/firm-list'],
      roles: ["ROLE_FRC_ADMIN", "ROLE_SYSTEM"]
    },
    {
      code: '105',
      name: 'Task Management',
      isRenewal: false,
      icon: 'fas fa-tachometer-alt',
      path: ['/frc/task'],
      roles: ["ROLE_FRC_ADMIN", "ROLE_SYSTEM"]
    },
    
    {
      code: '106',
      name: 'Auditor Publish',
      isRenewal: false,
      icon: 'fas fa-tachometer-alt',
      path: ['/frc/auditor-approved'],
      roles: ["ROLE_FRC_PUBLISHER"]
    },
    {
      code: '107',
      name: 'Firm Publish',
      isRenewal: false,
      icon: 'fas fa-tachometer-alt',
      path: ['/frc/firm-approved'],
      roles: ["ROLE_FRC_PUBLISHER"]
    },
    {
      code: '107',
      name: 'Payment Management',
      isRenewal: false,
      icon: 'fas fa-tachometer-alt',
      path: ['/frc/payment-0001'],
      roles: ["ROLE_FRC_ADMIN", "ROLE_SYSTEM"]
    },
  ];

}






