import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CselectComponent } from './components/cselect/cselect.component';
// import { CommonValidationService } from '@/mycommon/services/common-validation.service';
import { ConfirmationService } from 'primeng/api';
import { FileManagerComponent } from './components/file-manager/file-manager.component';
import {DialogModule} from 'primeng/dialog';
import {ButtonModule} from 'primeng/button';
import {TabViewModule} from 'primeng/tabview';
import {FileUploadModule} from 'primeng/fileupload';
import {ProgressBarModule} from 'primeng/progressbar';
import {GalleriaModule} from 'primeng/galleria';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { FileComponent } from './components/file/file.component';
import { LoaderComponent } from './components/loader/loader.component';



@NgModule({
  declarations: [
    CselectComponent,
    FileManagerComponent,
    DropdownComponent,
    FileComponent,
    LoaderComponent,
  ],
  imports: [
    CommonModule,
    DialogModule,
    ButtonModule,
    TabViewModule,
    FileUploadModule,
    ProgressBarModule,
    GalleriaModule
  ],
  providers: [ ConfirmationService],
  exports: [CselectComponent, FileManagerComponent, FileComponent,LoaderComponent],
})
export class MycommonModule { }
