import { Component, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Config } from '@/const';
import { SignupUserService } from '@services/signup.service';
import { Router } from '@angular/router';
import { HelperService } from '@services/helper.service';
@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
  providers: [SignupUserService, MessageService, HelperService]
})
export class RegistrationComponent implements OnInit {
  signupForm: UntypedFormGroup;
  otpForm: UntypedFormGroup;
  submitted = false;
  isLoading = false;
  message = ""
  isEnableEmailForm = true;

  constructor(
    public dialog: MatDialog,
    private primengConfig: PrimeNGConfig,
    private fb: UntypedFormBuilder,
    private ss: SignupUserService,
    private ms: MessageService,
    private router: Router,
    private _helper: HelperService
  ) { }

  public Logo = '';
  ngOnInit(): void {
    this.Logo = Config.frcLogo;
    this.signupForm = this.fb.group({
      email: ['', Validators.compose([Validators.required, Validators.email])]
    });

    this.otpForm = this.fb.group({
      email: [''],
      otp: ['', Validators.compose([Validators.required])],
    });
  }


  


  get f() {
    return this.signupForm.controls;
  }

  get o() {
    return this.otpForm.controls;
  }


  SendEmailVerificationCode() {
   
    if(this.signupForm.valid){
      this.isLoading = true;
      // this.ms.add({severity:'success', summary:'FRCBD will send a verification code to '+ this.signupForm.get('email').value, detail: ''});
      this.ss.sendOTPToEmail(this.signupForm.value)
      .then(res =>{
        this.isEnableEmailForm = false;
        alert(res.message)
        this.isLoading = false;
        //this.ms.add({ severity: 'success', summary: res.message, detail: '' });
      })
      .catch(e =>{
        this.isLoading = false;
        alert("Failed to send OTP")
      })
    }  

  }

  verifyEmailVerificationCode() {    
    if(this.otpForm.valid){
      this.isLoading = true;
      this.isEnableEmailForm = false;
      let email = this.signupForm.get('email').value;
      this.otpForm.get('email').setValue(email);
      this.ss.verifyOTPToEmail(this.otpForm.value)
      .then(res =>{
        alert(res.message)
        this.isLoading = false;
        sessionStorage.setItem("signup_email", email);
        this.router.navigate(['/mobile-verify'])
      })
      .catch(e =>{
        this.isLoading = false;
        alert("Invalid OTP")
      })
    }  

  }


}

