<section class="content">

    <div class="container-fluid shadow p-3 rounded">
        <div class="main-body">
            <div class="row">
                <div class="col-lg-4 mt-2">
                    <div class="card">
                        <div class="card-header">
                            <a href="javascript:void(0)"><i (click)="updatePhoto($event)" _ngcontent-uuu-c450="" class="btn btn-outline-none btn-xs fa fa-edit"></i></a>
                            
                        </div>
                        <div class="card-body">

                            <div class="d-flex flex-column align-items-center text-center">
                                <img [src]="getPhotoUrl(user?.url)" alt="user profile picture"
                                    class="rounded-circle p-1 bg-secondary" width="120">
                                <div>
                                    <h4 class="font-weight-bold mt-2">{{user?.name}}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-8">
                    <div class="card mb-4 mt-2">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-3">
                                    <label>Full Name </label>
                                </div>
                                <div class="col-sm-9">
                                    <p>{{user?.name}}</p>
                                </div>
                            </div>
                            <hr>
                            <hr>
                            <div class="row">
                                <div class="col-sm-3">
                                    <label>Mobile Number</label>
                                </div>
                                <div class="col-sm-9">
                                    <p>{{user?.mobile}}</p>
                                </div>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-sm-3">
                                    <label>Email Address</label>
                                </div>
                                <div class="col-sm-9">
                                    <p>{{user?.email}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</section>