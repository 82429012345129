<!-- Brand Logo -->


<!-- Sidebar -->
<div class="sidebar">
    <!-- Sidebar user (optional) -->
    <div class="user-panel mt-3 pb-3 mb-3 d-flex">
        <div class="image">
            <a routerLink="/profile">
                <img [src]="user?.photoUrl || 'assets/img/default-profile.png'" class="img-circle elevation-2"
                    alt="User Image" />
            </a>
        </div>
        <div class="info d-flex flex-column align-items-center"> <!-- Use flex-column to stack items vertically -->
            <a [routerLink]="['/']" class="d-block">
                {{ user?.name }}               
            </a>
            
            <div class="mt-2"></div> <!-- Add margin-top to create space between the links -->
            
            <a style="color: white; padding: .75ch; background: darkorange; border-radius: 55%;">
                {{ user.enlistedAuditor ? 'Enlistment Renewal' : 'Enlistment' }}
            </a>
        </div>
    </div>

    <!-- Sidebar Menu -->
    <nav class="mt-2" style="overflow-y: hidden">
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
            <app-menu-item *ngFor="let item of menu" [menuItem]="item"></app-menu-item>
        </ul>
    </nav>
</div>