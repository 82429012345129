<label [for]="ID" class="select-label">
  <ng-content></ng-content>
</label>
<select
  [ngClass]="{
      'form-control': type === 'default',
      'custom-select': type === 'custom'
  }"
  [id]="ID"
  [name]="ID"
  [disabled]="disabled"
  (change)="onValueChange($event)"
>
  <!-- <option [selected]="isNoneSelected()">Select None</option> -->
  <option
      *ngFor="let option of options"
      [value]="option.value"
      [selected]="value === option.value"
  >
      {{ option.label }}
  </option>
</select>
