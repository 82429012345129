
<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-9 bg">
                <!-- <div class="text-left">
                    <a class="h1">
                        <img src="{{Logo}}" alt="logo">
                        <b></b></a>
                </div> -->
                <div class="header-site-info" id="header-site-info">

                    <div>

                        <div id="logo">
                                                <a title="

                                " href="/">
                                    <img alt="logo" height="70" src="{{Logo}}">
                                </a>

                        </div>

                        <!-- <div class="clearfix" id="site-name-wrapper">
                            <span id="site-name">
                                <a title="Home" href="/">
                                                        </a>
                            </span>
                                            <span id="site-name">
                                <a title="
                                    ফাইনান্সিয়াল রিপোর্টিং কাউন্সিল					" href="/">
                                    ফাইনান্সিয়াল রিপোর্টিং কাউন্সিল					</a>
                            </span>
                                <span id="slogan">
                                অর্থ বিভাগ, অর্থ মন্ত্রণালয়				</span>
                        </div> -->

                    </div>

                </div>
                <div class="row" style="margin-top: 80px;">

                    <div style="height: 90vh; width: 100%;">
                        <div class="body-text-login">
                            <h3>
                                Auditor and Audit Firm
                            </h3>
                            <h3>Enlistment Portal.
                            </h3>
                        </div>
                    </div>


                </div>

            </div>
            <div class="col-md-3 border shadow-sm pb-0.5" style="background-color: #f3f3f3;">
                <div class="container ">
                    <h1 class="text-center mt-5">Sign in to account
                    </h1>



                    <form>
                        <div class="form-group p-2">
                            <button (click)="loginByFRCAuthenticationSystem()" class="btn btn-primary lg-button "
                                type="button">Login</button>
                        </div>
                        <div class="form-group p-2 line">
                            <hr class="line">
                        </div>
                        <div class="form-group p-2">
                            <button class="btn btn-primary lg-button-reg " [routerLink]="['/registration']"
                                type="button">Create an account</button>
                        </div>

                    </form>

                </div>

            </div>
        </div>
    </div>

</section>

<app-footer></app-footer>
