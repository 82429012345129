import { API } from '@/const';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FirmApplicableType } from '@modules/mycommon/enum/firm_applicable_type';
@Injectable({
  providedIn: 'root'
})
export class FirmService {

  constructor(
    private http: HttpClient
  ) { }

  createFirmBasicInfo(data: any) {
    return this.http.post<any>(API.BASE_API_URL + API.FIRM_BASIC_INFO, data)
      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });
  }

  async updateApplicable(isApplicableOrYes: boolean, type:FirmApplicableType) {
    let data = await this.firmAppBuild(isApplicableOrYes, type);
    return this.http.post<any>(API.BASE_API_URL + API.UPDATE_APPLICABLE_FOR_FIRM_BASIC_INFO, data)
      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });
  }

  firmAppBuild(isApplicableOrYes: boolean, type:FirmApplicableType) {
    let data = {}
    switch (type) {
      case FirmApplicableType.AUDITOR_PUN_IN_3_YEARS:
        data['audPunIn3Years'] = isApplicableOrYes;
        data['firmApplicableType'] = FirmApplicableType.AUDITOR_PUN_IN_3_YEARS;
        break;
      case FirmApplicableType.FIRM_PUN_IN_3_YEARS:
        data['firmPun3In3Years'] = isApplicableOrYes;
        data['firmApplicableType'] = FirmApplicableType.FIRM_PUN_IN_3_YEARS;
        break;
      case FirmApplicableType.ABLE_FOR_IT_AUDIT:
        data['ableForITAudit'] = isApplicableOrYes;
        data['firmApplicableType'] = FirmApplicableType.ABLE_FOR_IT_AUDIT;
        break;
      case FirmApplicableType.ABLE_FOR_IT_BANK_AUDIT:
        data['ableForITBankAudit'] = isApplicableOrYes;
        data['firmApplicableType'] = FirmApplicableType.ABLE_FOR_IT_BANK_AUDIT;
        break;
      case FirmApplicableType.YES_TO_AUDIT_PART_A:
        data['yesToAuditPartA'] = isApplicableOrYes;
        data['firmApplicableType'] = FirmApplicableType.YES_TO_AUDIT_PART_A;
        break;
      case FirmApplicableType.YES_TO_AUDIT_PART_B:
        data['yesToAuditPartB'] = isApplicableOrYes;
        data['firmApplicableType'] = FirmApplicableType.YES_TO_AUDIT_PART_B;
        break;
      case FirmApplicableType.YES_TO_AUDIT_PART_C:
        data['yesToAuditPartC'] = isApplicableOrYes;
        data['firmApplicableType'] = FirmApplicableType.YES_TO_AUDIT_PART_C;
        break;
      case FirmApplicableType.YES_TO_AUDIT_PART_D:
        data['yesToAuditPartD'] = isApplicableOrYes;
        data['firmApplicableType'] = FirmApplicableType.YES_TO_AUDIT_PART_D;
        break;
      case FirmApplicableType.YES_TO_AUDIT_PART_DB:
        data['yesToAuditPartDB'] = isApplicableOrYes;
        data['firmApplicableType'] = FirmApplicableType.YES_TO_AUDIT_PART_DB;
        break;
    }
    return data;
  }
  getFirmBasicInfo() {
    return this.http.get<any>(API.BASE_API_URL + API.FIRM_BASIC_INFO)
      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });
  }

  createFirmAddress(data: any) {
    return this.http.post<any>(API.BASE_API_URL + API.FIRM_ADDRESS, data)
      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });
  }
  getFirmAddress(id: any) {
    return this.http.get<any>(API.BASE_API_URL + API.GET_FIRM_ADDRESS_BY_FIRM_ID + id)
      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });
  }
  //create On going engament
  createFirmOngonigEngamentFee(data: any) {
    return this.http.post<any>(API.BASE_API_URL + API.FIRM_ONGOING_ENGAGEMENT_FEE, data)
      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });
  }
  getFirmOngonigEngamentFee(id: any) {
    return this.http.get<any>(API.BASE_API_URL + API.GET_FIRM_ONGOING_ENGAGEMENT_FEE + id)
      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });
  }
  deleteFirmOngonigEngamentFee(id) {
    return this.http.delete<any>(API.BASE_API_URL + API.DELETE_FIRM_ONGOING_ENGAMENT_FEE + id)
      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });
  }



  //it Profetional
  createFirmItProfetional(data: any) {
    return this.http.post<any>(API.BASE_API_URL + API.CREATE_FIRM_IT_PROFETIONAL, data)
      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });
  }
  getAllFirmItProfetional(firmId: any) {

    return this.http.get<any>(API.BASE_API_URL + API.GET_ALL_FIRM_IT_PROFETIONAL + firmId)
      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });
  }
  getOneFirmItProfetional(tableId: any) {
    return this.http.get<any>(API.BASE_API_URL + API.GET_ALL_FIRM_IT_PROFETIONAL + tableId)
      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });
  }
  deleteFirmItProfetional(id) {
    return this.http.delete<any>(API.BASE_API_URL + API.DELETE_FIRM_IT_PROFETIONAL + id)
      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });
  }



  //it System
  createFirmItSystem(data: any) {
    return this.http.post<any>(API.BASE_API_URL + API.CREATE_FIRM_IT_SYSTEM, data)
      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });
  }
  getAllFirmItSystem(firmId: any) {

    return this.http.get<any>(API.BASE_API_URL + API.GET_ALL_FIRM_IT_SYSTEM + firmId)
      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });
  }
  getOneFirmItSystem(tableId: any) {
    return this.http.get<any>(API.BASE_API_URL + API.GET_ALL_FIRM_IT_SYSTEM + tableId)
      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });
  }
  deleteFirmItSystem(id) {
    return this.http.delete<any>(API.BASE_API_URL + API.DELETE_FIRM_IT_SYSTEM + id)
      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });
  }



  //Firm Deposite fEE
  createFirmDepositeFee(data: any) {
    return this.http.post<any>(API.BASE_API_URL + API.CREATE_FIRM_DEPOSITE_FEE, data)
      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });
  }
  getAllFirmDepositeFee(firmId: any) {
    return this.http.get<any>(API.BASE_API_URL + API.GET_ALL_FIRM_DEPOSITE_FEE + firmId)
      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });
  }
  getOneFirmDepositeFee(tableId: any) {
    return this.http.get<any>(API.BASE_API_URL + API.GET_ONE_FIRM_DEPOSITE_FEE + tableId)
      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });
  }

  getFirmPaymentDetails() {
    return this.http.get<any>(API.BASE_API_URL + API.GET_ONE_FIRM_DEPOSITE_FEE)
      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });
  }

  deleteFirmDepositeFee(id) {
    return this.http.delete<any>(API.BASE_API_URL + API.DELETE_FIRM_DEPOSITE_FEE + id)
      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });
  }

  //Firm Part A
  createFirmPartA(data: any) {
    return this.http.post<any>(API.BASE_API_URL + API.CREATE_FIRM_PART_A, data)
      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });
  }
  getAllFirmPartA(firmId: any) {

    return this.http.get<any>(API.BASE_API_URL + API.GET_ALL_FIRM_PART_A + firmId)
      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });
  }
  getOneFirmPartA(tableId: any) {
    return this.http.get<any>(API.BASE_API_URL + API.GET_ALL_FIRM_PART_A + tableId)
      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });
  }
  deleteFirmPartA(id) {
    return this.http.delete<any>(API.BASE_API_URL + API.DELETE_FIRM_PART_A + id)
      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });
  }




  //Firm Part B Attachment
  createFirmPartBattachment(data: any) {
    return this.http.post<any>(API.BASE_API_URL + API.CREATE_FIRM_PART_B_ATTACHMENT, data)
      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });
  }
  getAllFirmPartBattachment(firmId: any) {

    return this.http.get<any>(API.BASE_API_URL + API.GET_ALL_FIRM_PART_B_ATTACHMENT + firmId)
      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });
  }

  deleteFirmPartBattachment(id) {
    return this.http.delete<any>(API.BASE_API_URL + API.DELETE_FIRM_PART_B_ATTACHMENT + id)
      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });
  }


  //Firm Part B dETAILS
  createFirmPartB_Details(data: any) {
    return this.http.post<any>(API.BASE_API_URL + API.CREATE_FIRM_PART_B_DETAILS, data)
      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });
  }
  getAllFirmPartB_Details(firmId: any) {

    return this.http.get<any>(API.BASE_API_URL + API.GET_ALL_FIRM_PART_B_DETAILS + firmId)
      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });
  }
  getOneFirmPartB_Details(tableId: any) {
    return this.http.get<any>(API.BASE_API_URL + API.GET_ALL_FIRM_PART_B_DETAILS + tableId)
      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });
  }
  deleteFirmPartB_Details(id) {
    return this.http.delete<any>(API.BASE_API_URL + API.DELETE_FIRM_PART_B_DETAILS + id)
      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });
  }



  //Firm Part B C dETAILS
  createFirmPartB_C_Details(data: any) {
    return this.http.post<any>(API.BASE_API_URL + API.CREATE_FIRM_PART_B_C_DETAILS, data)
      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });
  }
  getAllFirmPartB_C_Details(firmId: any) {

    return this.http.get<any>(API.BASE_API_URL + API.GET_ALL_FIRM_PART_B_C_DETAILS + firmId)
      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });
  }
  getOneFirmPartB_C_Details(tableId: any) {
    return this.http.get<any>(API.BASE_API_URL + API.GET_ALL_FIRM_PART_B_C_DETAILS + tableId)
      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });
  }
  deleteFirmPartB_C_Details(id) {
    return this.http.delete<any>(API.BASE_API_URL + API.DELETE_FIRM_PART_B_C_DETAILS + id)
      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });
  }




  //Firm Part C dETAILS
  createFirmPartC_Details(data: any) {
    return this.http.post<any>(API.BASE_API_URL + API.CREATE_FIRM_PART_C_DETAILS, data)
      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });
  }
  getAllFirmPartC_Details(firmId: any) {

    return this.http.get<any>(API.BASE_API_URL + API.GET_ALL_FIRM_PART_C_DETAILS + firmId)
      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });
  }
  getOneFirmPartC_Details(tableId: any) {
    return this.http.get<any>(API.BASE_API_URL + API.GET_ALL_FIRM_PART_C_DETAILS + tableId)
      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });
  }
  deleteFirmPartC_Details(id) {
    return this.http.delete<any>(API.BASE_API_URL + API.DELETE_FIRM_PART_C_DETAILS + id)
      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });
  }


  //Firm Part C
  createFirmPartD(data: any) {
    return this.http.post<any>(API.BASE_API_URL + API.CREATE_FIRM_PART_D, data)
      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });
  }
  getAllFirmPartD(firmId: any) {

    return this.http.get<any>(API.BASE_API_URL + API.GET_ALL_FIRM_PART_D + firmId)
      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });
  }
  getOneFirmPartD(tableId: any) {
    return this.http.get<any>(API.BASE_API_URL + API.GET_ALL_FIRM_PART_D + tableId)
      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });
  }
  deleteFirmPartD(id) {
    return this.http.delete<any>(API.BASE_API_URL + API.DELETE_FIRM_PART_D + id)
      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });
  }


  //Firm Part C
  createFirmPartD_Details(data: any) {
    return this.http.post<any>(API.BASE_API_URL + API.CREATE_FIRM_PART_D_DETAILS, data)
      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });
  }
  getAllFirmPartD_Details(firmId: any) {

    return this.http.get<any>(API.BASE_API_URL + API.GET_ALL_FIRM_PART_D_DETAILS + firmId)
      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });
  }
  getOneFirmPartD_Details(tableId: any) {
    return this.http.get<any>(API.BASE_API_URL + API.GET_ALL_FIRM_PART_D_DETAILS + tableId)
      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });
  }
  deleteFirmPartD_Details(id) {
    return this.http.delete<any>(API.BASE_API_URL + API.DELETE_FIRM_PART_D_DETAILS + id)
      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });
  }

  // FIRM ATTACHMENT
  createFirmBasicAttachment(data: any) {
    return this.http.post<any>(API.BASE_API_URL + API.CREATE_FIRM_BASIC_ATTACHMENT, data)
      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });
  }
  getAllFirmBasicAttachment(firmId: any) {

    return this.http.get<any>(API.BASE_API_URL + API.GET_ALL_FIRM_BASIC_ATTACHMENT + firmId)
      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });
  }

  finalSubmit() {
    // debugger;
    return this.http.post<any>(API.BASE_API_URL + API.FIRM_BRANCH_PREVIEW_SUBMIT, {})
      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });
  }

  getBranch(firmId) {
    return this.http.get(API.BASE_API_URL + API.GET_FIRM_BRANCH + '/' + firmId)
      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });
  }

  // get firm list for frc admin view
  searchFirm(query = ''){
    return this.http.get(API.BASE_API_URL + API.FRC_ADMIN_SEARCH_FIRM + '?'+query)
    .toPromise()
    .then(res => <any>res)
    .then(data => { return data; });
  }

  editFirm(firmId){
    return this.http.get(API.BASE_API_URL + API.FRC_ADMIN_EDIT_FIRM + '/'+firmId)
    .toPromise()
    .then(res => <any>res)
    .then(data => { return data; });
  }

}
