import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router, Routes } from "@angular/router";
import { FirmService } from "@services/firm.service";
import { FrcService } from "@services/frc.service";
import { log } from "console";
import { MegaMenuItem } from "primeng/api";
import { HostBinding } from "@angular/core";
import { DateTime } from "luxon";
import packageInfo from "./../../../../../package.json";

@Component({
  selector: "app-enlistment-list",
  templateUrl: "./enlistment-list.component.html",
  styleUrls: ["./enlistment-list.component.scss"],
})
export class EnlistmentListComponent implements OnInit {
  routes: Routes = [
    {
      path: "enlistment-list/enlistedAuditFirmCA",
      component: EnlistmentListComponent,
      pathMatch: "full",
    },
    {
      path: "enlistment-list/enlistedAuditFirmFCMA",
      component: EnlistmentListComponent,
      pathMatch: "full",
    },
  ];
  constructor(
    private frcService: FrcService,
    private router: Router,
    private fs: FirmService,
    private route: ActivatedRoute
  ) {}

  public appVersion = packageInfo.version;
  public currentYear: string = DateTime.now().toFormat("y");
  visibleFirmAuditorDialog: boolean;
  products: any;
  selectedProduct: any;
  bodyShow: boolean = true;
  firmShow: boolean = false;
  AuditorShow: boolean = false;
  publishedListForFirm: any;
  publishedListForAuditor: any;
  isLoading: boolean;
  shouldAddClass: boolean;
  shouldAddClassSub: boolean;
  shouldAddClassSub2: boolean;
  viewedAuditors: any[] = [];
  ORG: string;
  selectedType:string = '1';
  searchData:any[] = [];
  items: MegaMenuItem[] | undefined;
  ngOnInit(): void {

    this.route.queryParams.subscribe(params => {
      // Access query parameters here
      const type = params['type'];

      if(type=='caf'){
        this.enlistedAuditFirmCA();
      }else if(type=='cmaf'){
        this.enlistedAuditFirmFCMA();
      }else if(type=='ca'){
        this.enlistedAuditFirmCAwithCOP();
      }else if(type=='caq'){
        this.enlistedAuditFirmCAwithOutCOP();
      }else if(type == 'cma'){
        this.enlistedAuditFirmCMAwithCOP();
      }else if (type == 'cmaq'){
        this.enlistedAuditFirmCMAwithOutCOP();
      }
      
      
    });
    
    this.searchKeyword();
  }
  
  toggleClass() {
    this.shouldAddClass = !this.shouldAddClass;
  }

  toggleSubClass() {
    this.shouldAddClassSub = !this.shouldAddClassSub;
  }
  toggleSub2Class() {
    this.shouldAddClassSub2 = !this.shouldAddClassSub2;
  }

  getFirmPublishedList(type: string) {
    this.isLoading = true;

    this.frcService
      .getFirmPublishedList(type)
      .then((res) => {
        try {
          this.isLoading = false;
          this.publishedListForFirm = res;
        } catch (error) {
          console.log(error);
        }
      })
      .catch((err) => {});
  }

  getAuditorPublishedList(type: string, COP: boolean) {
    this.isLoading = true;
    this.frcService
      .getAuditorPublishedList(type, COP)
      .then((res) => {
        try {
          debugger;
          this.publishedListForAuditor = res;
          console.log(this.publishedListForAuditor);
          this.isLoading = false;
        } catch (error) {
          console.log(error);
        }
      })
      .catch((err) => {});
  }

  changeType(type: string){
    this.selectedType = type;
    this.searchKeyword();
  }
  searchText: string = '';
  changeText(searchText: string = ""){
    this.searchText = searchText;
  }

  searchKeyword(){debugger;
    this.frcService.searchFirmsOrAuditors(parseInt(this.selectedType), this.searchText)
    .then(res=>{
      this.searchData = res;
    }).catch(err=>{
      
    })
  }

  viewAuditors(auditors: any){debugger;
    this.viewedAuditors = [];
    this.viewedAuditors = auditors;
    this.visibleFirmAuditorDialog = true;
  }

  onDialogHide(){
    this.visibleFirmAuditorDialog = false;
  }

  enlistedAuditFirmCA() {
    this.firmShow = true;
    this.bodyShow = false;
    this.AuditorShow = false;
    let type = "CA";
    this.shouldAddClassSub = false;
    this.getFirmPublishedList(type);
  }
  enlistedAuditFirmFCMA() {
    this.firmShow = true;
    this.bodyShow = false;
    this.AuditorShow = false;
    let type = "CMA";
    this.shouldAddClassSub = false;
    this.getFirmPublishedList(type);
  }

  enlistedAuditFirmCAwithCOP() {
    this.firmShow = false;
    this.bodyShow = false;
    this.AuditorShow = true;
    this.ORG = "ICAB";
    let type = "CA";
    let COP = true;
    this.shouldAddClassSub2 = false;
    this.getAuditorPublishedList(type, COP);
  }
  enlistedAuditFirmCAwithOutCOP() {
    this.firmShow = false;
    this.bodyShow = false;
    this.AuditorShow = true;
    this.ORG = "ICAB";
    let type = "CA";
    let COP = false;
    this.shouldAddClassSub2 = false;
    this.getAuditorPublishedList(type, COP);
  }
  enlistedAuditFirmCMAwithCOP() {
    this.firmShow = false;
    this.bodyShow = false;
    this.AuditorShow = true;
    this.ORG = "ICMAB";
    let type = "CMA";
    let COP = true;
    this.shouldAddClassSub2 = false;
    this.getAuditorPublishedList(type, COP);
  }
  enlistedAuditFirmCMAwithOutCOP() {
    this.firmShow = false;
    this.bodyShow = false;
    this.AuditorShow = true;
    this.ORG = "ICMAB";
    let type = "CMA";
    let COP = false;
    this.shouldAddClassSub2 = false;
    this.getAuditorPublishedList(type, COP);
  }
}
