import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {v4 as uuidv4} from 'uuid';


@Component({
  selector: 'app-cselect',
  templateUrl: './cselect.component.html',
  styleUrls: ['./cselect.component.scss']
})
export class CselectComponent implements OnInit {
  @HostBinding('class') classes: string = 'form-group';
    public ID: string;
    @Input() type: string;
    @Input() disabled: boolean;
    @Input() options: Array<Option>;
    @Input() value: string;
    @Output() valueChange = new EventEmitter<string>();

    constructor() {}

    ngOnInit(): void {
        this.ID = uuidv4();
    }

    ngOnChanges() {
      if(this.value) {this.value = this.value}
    }

    onValueChange(event) {
        this.valueChange.emit(event.target.value);
    }

    public isNoneSelected(): boolean {
        if (!this.value) {
            return true;
        }
        return this.options.some(
            (option: Option) => option.value === this.value
        );
    }
}

export interface Option {
    label: string;
    value: string | number;
}
