import { Injectable } from '@angular/core';
import { API } from '@/const';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class FrcService {

  constructor(
    private http: HttpClient
  ) { }

  getAuditorView(ststus,searchText,pageNumber,pageSize) {
    return this.http.get<any>(API.BASE_API_URL + API.FRC_BACK_OFFICE_AUDITOR_VIEW+'?status='+ststus+'&searchText='+searchText+'&page='+pageNumber+'&size='+pageSize)
      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });
  }

  searchFirmsOrAuditors(type=1, searchText) {
    return this.http.get<any>(API.BASE_API_URL + API.GET_FIRM_AND_AUDITOR_BY_TEXT+'/'+type+'?searchText='+searchText+'')
      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });
  }

  auditorEdit(id) {
    return this.http.get<any>(API.BASE_API_URL + API.FRC_BACK_OFFICE_AUDITOR_EDIT+'/'+id)
      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });
  }


  getTaskList() {
    return this.http.get<any>(API.BASE_WORKFLOW_URL + API.WORKFLOW_FRC_GET_TAST_LIST)
      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });
  }
  getDoer() {
    return this.http.get<any>(API.BASE_WORKFLOW_URL + API.WF_DOER_BY_USERNAME)
      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });
  }
  enlistmentApporvalStatusSubmit(data: any) {
    return this.http.post<any>(API.BASE_WORKFLOW_URL + API.WORKFLOW_FRC_ENLISTMENT_APPROVAL_SUBMIT, data)
      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });
  }

  auditorPublshed(data: any, published: boolean) {
    return this.http.post<any>(API.BASE_API_URL + API.AUDITOR_PUBLISHED+"?published="+published, data)
      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });
  }

  firmPublshed(data: any, published= false) {
    return this.http.post<any>(API.BASE_API_URL + API.FIRM_PUBLISHED+"?published="+published, data)

      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });
  }
  getFirmPublishedList(type:any) {
    return this.http.get<any>(API.BASE_API_URL + API.GET_FIRM_PUBLISHED_LIST + type)
    .toPromise()
    .then(res => <any>res)
    .then(data => { return data; });
}


  // CA&has_cop=true/false

  getAuditorPublishedList(type:string,COP:boolean) {
    return this.http.get<any>(API.BASE_API_URL + API.GET_AUDITOR_PUBLISHED_LIST + type+"&has_cop="+COP)
      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });
  }

  getAllPaymentsForAdmin(page=0, size=20, searchText="") {
    return this.http.get<any>(API.BASE_API_URL + API.GET_PAYMENT_ALL_DATA_WIT_PAGINATION+"?page="+page+"&size="+size+"&searchText="+searchText)
      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });
  }

  updatePaymentStatus(data: any) {
    return this.http.post<any>(API.BASE_API_URL + API.UPDATE_PAYMENT_STATUS, data)
      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });
  }


}
