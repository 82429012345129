import { API } from '@/const';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Cookie } from 'ng2-cookies';
import { MessageService } from 'primeng/api';
import { ToWords } from 'to-words';


@Injectable({
  providedIn: 'root'
})
export class HelperService {
  img: any;
  constructor(private _http: HttpClient) { }

  toFormData(data: any): FormData {
    const formData = new FormData();
    Object.keys(data).forEach(key => formData.append(key, data[key]));
    return formData;
  }
  CheckEmail(email:any):boolean{

 //debugger;
 var regex = new RegExp("^[A-Za-z][_A-Za-z0-9-]*(\\.[_A-Za-z0-9-]+)*@[A-Za-z][_A-Za-z0-9-]*\\.[A-Za-z0-9]{2,6}$");
 return regex.test(email);

  }
  getFirmOnly() {
    return this._http.get<any>(API.BASE_API_URL + API.FIRM_BASIC_ONLY)
      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });
  }
  isValidatePhoneNumber(phone:any):boolean {
    let validateExp = /(^(\+88|0088)?(01){1}[13456789]{1}(\d){8})$/;
   return validateExp.test(phone);
  }



  printFormat(formatKey: string) {
    let reportFormatMap = new Map();
    reportFormatMap.set('JPG', 'image/jpg');
    reportFormatMap.set('PNG', 'image/png');
    reportFormatMap.set('JPEG', 'image/jpeg');
    reportFormatMap.set('PDF', 'application/pdf');
    reportFormatMap.set('XLSX', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    reportFormatMap.set('DOCX', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document');
    return reportFormatMap.get(formatKey.toUpperCase());
  }

  showBlobToImage(imageBlob: Blob, imageId: string){
    var imageUrl = URL.createObjectURL(imageBlob);
    this.img = document.getElementById(imageId);
    this.img.src = imageUrl;
  }

  isEmptyObject(obj = {}) {
    if(obj == null){
      return true;
    }
    for(var prop in obj) {
      if(Object.prototype.hasOwnProperty.call(obj, prop)) {
        return false;
      }
    }

    return JSON.stringify(obj) === JSON.stringify({});
  }

  buildFormGroup(form: UntypedFormGroup, data: any){
    // formData.append(key, data[key]
    Object.keys(data).forEach(key => {
      try {
        form.get(key).setValue(data[key]);
      } catch (error) {}
    });
  }

  async isValidFileUrl(url: string){
    const res = await this._http.get(url).toPromise();
    return res;
  }

  showErrorMessage(error: any = {}, messageService: MessageService ){//debugger;
    try{
      if(error.status && error.status ==400){
        let err = error.error;
        if(err.status && err.status ==400){
          if(err.fieldErrors){
            for (const item of err.fieldErrors) {
              messageService.add({ severity: 'error', summary: item.field, detail: '' });
            }
          }
        }else if(err.errorCode==400){
          messageService.add({ severity: 'error', summary: 'Invalid attempt! Missing required data.', detail: '' });
        }else if(err.status=='Error'){
          messageService.add({ severity: 'error', summary: err.message, detail: '' });
        }
      }else if(error.status && error.status ==403){
        if(error.error.errorCode =='access_denied'){
          messageService.add({ severity: 'error', summary: 'Access Denied', detail: error.error.message });
        }else{
          messageService.add({ severity: 'error', summary: 'Invalid attempt!, Please contact with support team.', detail: '' });
        }

      }else{
        messageService.add({ severity: 'error', summary: 'Invalid attempt!, Please contact with support team.', detail: '' });
      }
    }catch(_){
      messageService.add({ severity: 'error', summary: 'Invalid attempt!, Please contact with support team.', detail: '' });
    }
  }

  hasRole(roles=[], roleName = ''){
    for (const role of roles) {
      if(role == roleName) return true;
    }
    return false;
  }

  amountToWords(amount) {
    const toWords = new ToWords();
    return toWords.convert(amount);
  }

  getMe() {
    return this._http.get<any>(API.BASE_API_URL + API.FIND_USER_ME)
      .toPromise()
      .then(res => <any>res)
      .then(res => {
        return res;
       });
  }

  checkEnlistmentOrRenewal(id: any): boolean {
    // Return false if id is null, undefined, or an empty string
    if (id === null || id === undefined || id === '') {
      return false;
    }
    return true;
  }

}
