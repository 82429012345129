import { browser } from 'protractor';
import { API } from '@/const';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HelperService } from './helper.service';
import { StorageService } from './storage.service';
import { AuthConfig as authconfig } from '@/sso.config';
import { Cookie } from 'ng2-cookies';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ActivatedRouteSnapshot } from '@angular/router';


@Injectable({
  providedIn: 'root',
})
export class AuthService {

  constructor(private http: HttpClient,
    private tokenService: StorageService,
    private tokenVerify: JwtHelperService,
    private _helper: HelperService) { }



  retrieveToken(code) {
    let params = new URLSearchParams();
    params.append('grant_type', 'authorization_code');
    params.append('scope', authconfig.scope);
    params.append('code', code);

    let headers =
      new HttpHeaders(
        {

          'Authorization': "Basic " + btoa(authconfig.clientId + ':' + authconfig.ClientSecret),
          'Cookie': 'JSESSIONID=' + Cookie.get('JSESSIONID')
        });

    this.http.post(authconfig.token_url + '?scope=admin&code=' + code + '&grant_type=authorization_code', null, { headers: headers })
      .subscribe(
        data => this.saveToken(data),
        err => {
          console.log(err, "=================err");
        });
  }

  saveToken(token) {//debugger;
    var expireDate = new Date().getTime() + (authconfig.sessionTime * token.expires_in);
    Cookie.set("access_token", token.access_token, new Date(expireDate));
    // console.log('Obtained Access token');

    window.location.href = window.location.origin;
    // this.getMe();

  }



  checkCredentials() {
    return Cookie.check('access_token');
  }

  getTokenFromCookie() {
    return Cookie.get('access_token');
  }

  logoutAuth() {
    Cookie.delete('access_token');
    window.location.reload();
  }

  getFileExtention(filename: any) {
    try {
      return filename.split(".").pop();
    } catch (e) {
      return 'png';
      console.log(e);

    }
  }


  logout() {
    // remove user from local storage to log user out
    this.tokenService.logout();
  }

  hasAccessRole(route: ActivatedRouteSnapshot) {
    if (route.data.roles) {
      try {
            let jwt = this.tokenVerify.decodeToken(this.getTokenFromCookie());
            let roles = jwt.authorities;
            for (const el of route.data.roles) {
              if (roles.includes(el)) {
                return true;
              }
            }
            return false;
      } catch (error) {
        console.log(error);
      }

    }else{
      return true;
    }
    return false;
  }

  isTokenExpired(){
    return this.tokenVerify.isTokenExpired(this.getTokenFromCookie());
  }

  isAdmin(){
    return this.hasRole("ROLE_FRC_ADMIN");
  }

  hasRole(role: string){
    let token = this.tokenVerify.decodeToken(this.getTokenFromCookie());
    if(token){
      return token['authorities'].includes(role);
    }
    return false;
  }

  userLoggedIn(){
    let token = this.getTokenFromCookie();
    if(token ==""){
      return false;
    }
    return true;
  }


}
