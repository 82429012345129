import { API } from '@/const';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuditorService {

  constructor(private http: HttpClient) { }


  createBasicInfo(data: any){
    return this.http.post<any>(API.BASE_API_URL+API.AUDITOR_BASIC_INFO, data)
      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });
  }
  getAuditorBasicInfoId() {
    return this.http.get<any>(API.BASE_API_URL + API.GET_AUDITOR_BASIC_INFO)
      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });
  }

  getAuditrPriveiw() {
    return this.http.get<any>(API.BASE_API_URL + API.AUDITOR_BASIC_INFO_PREVIEW)
      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });
  }

  auditorFinalSubmit(data: any){
    return this.http.post<any>(API.BASE_API_URL+API.AUDITOR_BASIC_INFO_SUBMIT, data)
      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });
  }

  fileUpload(files: any) {
    const formData = new FormData();
    formData.append('file', files[0]);
   return  this.http
      .post<any>(API.BASE_API_URL + API.SAVE_FILE, formData)
      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });

  }
  auditorPayment(data: any){
    return this.http.post<any>(API.BASE_API_URL+API.AUDITOR_PAYMENT, data)
      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });
  }
  getauditorPaymentById() {
    return this.http.get<any>(API.BASE_API_URL + API.AUDITOR_PAYMENT)
      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });
  }

  onlinePay(data: any){
    return this.http.post<any>(API.BASE_API_URL+API.AUDITOR_ONLINE_PAYMENT, data)
      .toPromise()
      .then(res => <any>res)
      .then(data => { return data; });
  }

}
